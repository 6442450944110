import {
  Box,
  Fade,
  LinearProgress,
  Menu,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomModal from "../../../../components/shared/ui/custom-modal/custom-modal";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { formatISODate } from "../../../../utils/format-date";
import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { useGetQuery } from "../../../../api/useGetQuery";
import { CustomNoRowsOverlay } from "../../../../components/shared/ui/custom-no-row-overly/custom-no-row-overly";
import styles from "../table-messages/table-messages.module.scss";
import DateRangePickerMui from "../../../../components/shared/inputs/date-range-picker-mui/DateRangePickerMui";
import BasicRangeShortcuts from "../../../../components/shared/inputs/date-range-picker-mui/CustomShortcutItem";
import { useEffect, useState } from "react";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";

const style = {
  width: "80%",
  overflow: "scroll",
  maxHeight: "90vh",
};

const PreviousMessages = (props) => {
  const {
    openModal,
    handleCloseModal,
    clientId,
  } = props;

  const isScreenLarge = useMediaQuery("(min-width: 1500px)");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const openDateRange = Boolean(anchorEl);

  const { data, isLoading } = useGetQuery(
    `messages-platforms-clients-list-${startDate}-${endDate}-${clientId}`,
    "/messages",
    {
      isDeleted: 2,
      startDate,
      endDate,
      clientId,
    }
  );

  const { mutateAsync } = useMutateQuery();

  useEffect(() => {
    const currentDate = dayjs();
    const fourYearsAgo = currentDate.subtract(10, "year");

    setStartDate(fourYearsAgo.toISOString());
    setEndDate(currentDate.toISOString());
  }, []);

  const columns: any[] = [
    {
      field: "is_seen",
      headerName: "مقروءة",
      width: 50,
      renderCell: (params) => {
        return (
          <LightTooltip title={"تعيين كمقروءة مباشر"}>
            {params?.row?.is_live_read ? (
              <Box
                sx={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#F9FBF8",
                  padding: "4px",
                  backgroundColor: "#62C16C",
                  border: "1px solid #62C16C",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  mutateAsync({
                    url: `/messages/${params?.row?.id}`,
                    body: {
                      isLiveRead: "false",
                    },
                    method: "patch",
                  });
                }}
              >
                <i className="bx bx-message-square-check"></i>
              </Box>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "#62C16C",
                  padding: "4px",
                  backgroundColor: "#F8FBFA",
                  border: "1px solid #62C16C",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  mutateAsync({
                    url: `/messages/${params?.row?.id}`,
                    body: {
                      isLiveRead: "true",
                    },
                    method: "patch",
                  });
                }}
              >
                <i className="bx bx-message-square-check"></i>
              </Box>
            )}
          </LightTooltip>
        );
      },
    },
    {
      field: "content",
      headerName: "الرسائل",
      width: 900,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              padding: "5px 0",
              fontSize: isScreenLarge ? "16px" : "14px",
            }}
            onDoubleClick={() => {
              if (params?.row?.is_live_read) {
                mutateAsync({
                  url: `/messages/${params.row.id}`,
                  body: {
                    isLiveRead: "false",
                  },
                  method: "patch",
                });
              } else {
                mutateAsync({
                  url: `/messages/${params.row.id}`,
                  body: {
                    isLiveRead: "true",
                  },
                  method: "patch",
                });
              }
            }}
          >
            {params?.row?.content}
            <Box sx={{ fontSize: isScreenLarge ? "14px" : "12px", color: "#2E73FF" }}>
              {formatISODate(params?.row?.created_at)}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "الاجراءات",
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <LightTooltip title={"اخفاء"} placement="right">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "24px",
                  cursor: "pointer",
                  color: params?.row?.deleted_at ? "#2E73FF" : "#14181F",
                }}
                onClick={() => {
                  if (params?.row?.deleted_at) {
                    mutateAsync({
                      url: `/messages/undelete/${params?.row?.id}`,
                      method: "patch",
                    });
                  } else {
                    mutateAsync({
                      url: `/messages/soft/${params?.row?.id}`,
                      method: "delete",
                    });
                  }
                }}
              >
                <i className="bx bx-hide"></i>
              </Box>
            </LightTooltip>
            <LightTooltip title={"مفضلة"} placement="right">
              <>
                {params?.row?.is_favorite ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "24px",
                      cursor: "pointer",
                      color: "red",
                    }}
                    onClick={() => {
                      mutateAsync({
                        url: `/messages/${params?.row?.id}`,
                        body: {
                          isFavorite: "false",
                        },
                        method: "patch",
                      });
                    }}
                  >
                    <i className="bx bxs-heart"></i>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "24px",
                      cursor: "pointer",
                      color: "#14181F",
                    }}
                    onClick={() => {
                      mutateAsync({
                        url: `/messages/${params?.row?.id}`,
                        body: {
                          isFavorite: "true",
                        },
                        method: "patch",
                      });
                    }}
                  >
                    <i className="bx bx-heart"></i>
                  </Box>
                )}
              </>
            </LightTooltip>
          </Box>
        );
      },
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateRange = (e: DateRange<Dayjs>) => {
    setStartDate(e[0]?.toISOString());
    setEndDate(e[1]?.toISOString());
  };

  return (
    <CustomModal
      customStyle={style}
      openModal={openModal}
      handleCloseModal={handleCloseModal}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          الرسائل السابقة
        </Typography>
        {/* <DateRangePickerMui
          startDate={startDate}
          endDate={endDate}
          handleClick={handleClick}
        />
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={openDateRange}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <BasicRangeShortcuts
            handleDateRange={handleDateRange}
            startDate={startDate}
            endDate={endDate}
          />
        </Menu> */}
      </Box>
      <br />
      <Box>
        <DataGridPro
          sx={{
            border: "none",
          }}
          columns={columns}
          rows={data?.array?.length > 0 ? data?.array : []}
          getRowHeight={() => "auto"}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowId={(row) => row?.id}
          autoHeight
          loading={isLoading}
          getRowClassName={(params) =>
            `${params?.row?.is_live_read ? styles.is_seen_row_true : ""}`
          }
        />
      </Box>
    </CustomModal>
  );
};

export default PreviousMessages;
