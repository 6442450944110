import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "6px",
  outline: "0",
};

type CustomModalTypes = {
  openModal: boolean;
  children: React.ReactNode;
  handleCloseModal: () => void;
  customStyle?: any;
};

const CustomModal: React.FC<CustomModalTypes> = (props) => {
  const { openModal, handleCloseModal, customStyle, children } = props;

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, ...customStyle }}>{children}</Box>
    </Modal>
  );
};

export default CustomModal;
