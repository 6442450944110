import { Box } from "@mui/material";
import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { useEffect, useState } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";

const ActionsCell = ({ params }) => {
  const [isFavorite, setIsFavorite] = useState(params?.is_favorite);

  const { mutateAsync, isError } = useMutateQuery();

  const handleFavoriteChange = (value) => {
    setIsFavorite(value);
    mutateAsync({
      url: `/messages/${params?.id}`,
      body: {
        isFavorite: value,
      },
      method: "patch",
    });
  };

  useEffect(() => {
    setIsFavorite(params?.is_favorite);
  }, [params?.is_favorite]);

  useEffect(() => {
    if (isError) {
      setIsFavorite(params?.is_favorite);
    }
  }, [isError]);

  return (
    <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <LightTooltip title={"اخفاء"} placement="right">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            cursor: "pointer",
            color: params?.deleted_at ? "#2E73FF" : "#14181F",
          }}
          onClick={() => {
            if (params?.deleted_at) {
              mutateAsync({
                url: `/messages/undelete/${params?.id}`,
                method: "patch",
              });
            } else {
              mutateAsync({
                url: `/messages/soft/${params?.id}`,
                method: "delete",
              });
            }
          }}
        >
          <VisibilityOffOutlinedIcon />
        </Box>
      </LightTooltip>
      <LightTooltip title={"مفضلة"} placement="right">
        <>
          {isFavorite ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "24px",
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => handleFavoriteChange("false")}
            >
              <FavoriteOutlinedIcon />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "24px",
                cursor: "pointer",
                color: "#14181F",
              }}
              onClick={() => handleFavoriteChange("true")}
            >
              <FavoriteBorderOutlinedIcon />
            </Box>
          )}
        </>
      </LightTooltip>
    </Box>
  );
};

export default ActionsCell;
