import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetQuery } from "../../../../api/useGetQuery";
import { useMutateQuery } from "../../../../api/useMutateQuery";

const EditableShowName = ({ data }) => {
  const { radio_show_name } = data;

  const [isOpened, setIsOpened] = useState(false);
  const [showValue, setShowValue] = useState({
    radioShowName: radio_show_name,
  });

  const { data: radioShows, isLoading } = useGetQuery(
    "radio-shows",
    "/radio-shows"
  );

  const { mutateAsync } = useMutateQuery();

  useEffect(() => {
    setIsOpened(true);
  }, []);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Autocomplete
        sx={{ width: "250px", "& *": { border: "none !important" } }}
        size="small"
        disablePortal
        open={isOpened}
        onOpen={() => setIsOpened(true)}
        onClose={() => setIsOpened(false)}
        options={isLoading ? [] : radioShows?.array}
        value={showValue?.radioShowName ? showValue : null}
        onChange={(_, newValue) => {
          setShowValue(newValue);
          mutateAsync({
            url: `/messages/${data?.id}`,
            body: {
              radioShowId: newValue?.id,
            },
            method: "patch",
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        getOptionLabel={(option: any) => option?.radioShowName}
      />
    </Box>
  );
};

export default EditableShowName;
