import { useState } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { api } from "../../../../api/interceptor";

const EditableClientLocation = (props) => {
  const { params: data, locationsHashMap } = props;

  const [options, setOptions] = useState([]);
  const [editedLocationAutoComplete, setEditedLocationAutoComplete] = useState({
    city_id: locationsHashMap[data?.id]?.city_id,
    city_name: locationsHashMap[data?.id]?.city_name,
    sub_city_id: locationsHashMap[data?.id]?.sub_city_id,
    sub_city_name: locationsHashMap[data?.id]?.sub_city_name,
    neighborhood_id: locationsHashMap[data?.id]?.neighborhood_id,
    neighborhood_name: locationsHashMap[data?.id]?.neighborhood_name,
  });

  const { mutateAsync } = useMutateQuery();

  const handleSteps = (value: any) => {
    setOptions([]);
    axios
      .get(
        `${
          import.meta.env.VITE_SOME_KEY_BASE_URL_LOCATION_MAKER
        }/auto-complete/${value}`
      )
      .then((response) => {
        console.log(response?.data);
        if (response.status === 200) {
          setOptions(response?.data);
        }
      });
  };

  function formatLocation(...locationComponents) {
    const nonEmptyComponents = locationComponents
      .filter((component) => component)
      ?.reverse();

    return nonEmptyComponents.join(" / ");
  }

  const getOptionLabel = (option) => {
    let governorate_name = option?.governorate_name;
    let city_name = option?.city_name;
    let sub_city_name = option?.sub_city_name;
    let neighborhood_name = option?.neighborhood_name;

    const formattedLocation = formatLocation(
      governorate_name,
      city_name,
      sub_city_name,
      neighborhood_name
    );

    return formattedLocation;
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Autocomplete
        sx={{ width: "250px", "& *": { border: "none !important" } }}
        size="small"
        disablePortal
        options={options?.length > 0 ? options : []}
        value={editedLocationAutoComplete}
        onChange={(_, newValue) => {
          handleSteps(newValue);
          setEditedLocationAutoComplete(newValue);
          mutateAsync({
            url: `/clients/${data?.client_id}`,
            body: {
              neighborhoodId: newValue?.neighborhood_id,
              subCityId: newValue?.sub_city_id,
              cityId: newValue?.city_id,
            },
            method: "patch",
          });
        }}
        onInputChange={(_, newValue) => {
          handleSteps(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        getOptionLabel={(option) => getOptionLabel(option)}
      />
    </div>
  );
};

export default EditableClientLocation;
