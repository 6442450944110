import React, { useState } from "react";
import CustomModal from "../../../../components/shared/ui/custom-modal/custom-modal";
import {
  FormControl,
  FormLabel,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import { CustomAutoComplete } from "../../../../components/shared/inputs/custom-auto-complete/custom-auto-complete";
import { useGetQuery } from "../../../../api/useGetQuery";

const FilterModal = (props) => {
  const {
    openModal,
    handleCloseModal,
    startDate,
    endDate,
    setEpisode,
    episode,
    radioShow,
    setRadioShow,
  } = props;

  const { data: radioShowsData, isLoading: isLoadingRadioShows } = useGetQuery(
    "radio-shows",
    "/radio-shows",
    {
      limit: 500,
    }
  );

  const { data: episodesData, isLoading: isLoadingEpisodes } = useGetQuery(
    `episodes-${radioShow?.id}-${startDate}-${endDate}`,
    "/episodes",
    {
      radioShowId: radioShow?.id,
      startDate: startDate,
      endDate: endDate,
    },
    {
      enabled: !!radioShow?.id,
    }
  );

  return (
    <CustomModal openModal={openModal} handleCloseModal={handleCloseModal}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        الفلترة
      </Typography>
      <br />
      <FormControl sx={{ width: "100%" }}>
        <FormLabel
          sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "0px" }}
        >
          البرامج
        </FormLabel>
        <CustomAutoComplete
          size="small"
          options={isLoadingRadioShows ? [] : radioShowsData?.array}
          getOptionLabel={(option: any) => option.radioShowName}
          value={radioShow || null}
          onChange={(e, value: any) => {
            setRadioShow(value);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="البرامج" />
          )}
        />
      </FormControl>
      <FormControl sx={{ width: "100%", marginTop: "20px" }}>
        <FormLabel
          sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "0px" }}
        >
          الحلقات
        </FormLabel>
        <CustomAutoComplete
          size="small"
          disabled={!radioShow?.id}
          options={isLoadingEpisodes ? [] : episodesData?.array}
          value={episode || null}
          getOptionLabel={(option: any) =>
            new Date(option.date).toLocaleDateString()
          }
          onChange={(e, value: any) => {
            setEpisode(value);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="الحلقات" />
          )}
        />
      </FormControl>
    </CustomModal>
  );
};

export default FilterModal;
