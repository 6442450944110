import { useState } from "react";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Fade,
  LinearProgress,
  Menu,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../../components/shared/ui/custom-no-row-overly/custom-no-row-overly";
import { useGetQuery } from "../../api/useGetQuery";
import useFixedHeader from "../../hooks/useFixedHeader";
import { handleIndexData } from "../../utils/handle-index-data";
import { handleColsWidth } from "../../utils/handle-cols-width";
import { AudioPlayer } from "../../components/shared/ui/custom-audio-player/custom-audio-player";
import { formatISODate } from "../../utils/format-date";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";
import DateRangePickerMui from "../../components/shared/inputs/date-range-picker-mui/DateRangePickerMui";
import BasicRangeShortcuts from "../../components/shared/inputs/date-range-picker-mui/CustomShortcutItem";
import MessagesModal from "./components/MessagesModal";
import { CustomAutoComplete } from "../../components/shared/inputs/custom-auto-complete/custom-auto-complete";

const ExpressYourself = () => {
  const limit = 20;
  const pageScrollPosition = 280;
  const [page, setPage] = useState(1);
  const [pollId, setPollId] = useState();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [colsWidth, setColsWidth] = useState(() => {
    const savedColsWidth = JSON.parse(
      localStorage.getItem("colsWidth-express-yourself")
    );
    return (
      savedColsWidth || {
        poll: 500,
        name: 150,
        date: 200,
        media: 400,
      }
    );
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const today = dayjs();
  const currentMonthRange: DateRange<Dayjs> = [
    today.add(3, "hours"),
    today.add(3, "hours"),
  ];

  const [startDate, setStartDate] = useState<string>(
    currentMonthRange[0].toISOString()
  );
  const [endDate, setEndDate] = useState<string>(
    currentMonthRange[1].toISOString()
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateRange = (e: DateRange<Dayjs>) => {
    setStartDate(e[0]?.toISOString());
    setEndDate(e[1]?.toISOString());
  };

  const { data: expressYourselfData, isLoading } = useGetQuery(
    `messages-express-yourself-${limit}-${page}-${startDate}-${endDate}-${pollId}`,
    "/poll-answers/express-your-opinion?relations[]=client&relations[]=poll",
    {
      limit: limit,
      offset: page * limit - limit,
      startDate: startDate,
      endDate: endDate,
      pollId: pollId,
    }
  );

  const { data: pollsCurrentMessage } = useGetQuery(
    `polls-messages-express`,
    "/forms/express-your-opinion"
  );

  const { data: pollsMessages, isLoading: isMessagesLoading } = useGetQuery(
    `polls-messages-express-dashboard`,
    "/forms/express-your-opinion/dashboard"
  );

  const { isHeaderFixed } = useFixedHeader({ pageScrollPosition });

  const columns: any[] = [
    {
      field: "poll.pollTitleArb",
      headerName: "السؤال",
      width: colsWidth?.poll,
      flex: colsWidth?.poll === 500 ? 1 : 0,
      renderCell: (params) => {
        return (
          <Box sx={{ padding: "10px 0" }}>
            {params?.row?.poll?.pollTitleArb}
          </Box>
        );
      },
    },
    {
      field: "client.fullName",
      headerName: "الاسم",
      width: colsWidth?.name,
      renderCell: (params) => {
        return (
          <Box sx={{ padding: "10px 0" }}>{params?.row?.client?.fullName}</Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "وقت الارسال",
      width: colsWidth?.date,
      renderCell: (params) => {
        return (
          <Box sx={{ padding: "10px 0" }}>
            {formatISODate(params?.row?.createdAt)}
          </Box>
        );
      },
    },
    {
      field: "audio",
      headerName: "الرسالة",
      width: colsWidth?.media,
      renderCell: (params) => {
        return params?.row?.filePath ? (
          <AudioPlayer
            src={`${import.meta.env.VITE_SOME_KEY_BASE_URL}/gallery?path=${
              params?.row?.filePath
            }`}
          />
        ) : (
          <Box>لايوجد</Box>
        );
      },
    },
  ];

  console.log(pollsMessages);

  return (
    <>
      <div style={{ fontSize: "22px", marginBottom: "20px" }}>عبر عن رايك</div>
      <Alert severity="info">
        <AlertTitle>السؤال الحالي (الاخير)</AlertTitle>
        <Typography component={"p"}>
          {pollsCurrentMessage?.polls?.length > 0
            ? pollsCurrentMessage?.polls[0]?.pollTitleArb
            : "لا يوجد"}
        </Typography>
      </Alert>
      <Box
        component="section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <CustomAutoComplete
          sx={{ width: "400px", maxWidth: "50%" }}
          size="small"
          disablePortal
          loading={isMessagesLoading}
          options={isMessagesLoading ? [] : pollsMessages?.polls}
          // value={showValue}
          onChange={(_, newValue: any) => {
            setPollId(newValue?.id);
            // setShowValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              placeholder="البحث عن السؤال"
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          getOptionLabel={(option: any) => option?.pollTitleArb}
        />
        <Box sx={{ display: "flex", gap: "10px" }}>
          <DateRangePickerMui
            startDate={startDate}
            endDate={endDate}
            handleClick={handleClick}
          />
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <BasicRangeShortcuts
              handleDateRange={handleDateRange}
              startDate={startDate}
              endDate={endDate}
            />
          </Menu>
          <Button
            onClick={() => setOpenMessageModal(true)}
            sx={{
              backgroundColor: "#f7f7f8",
              borderRadius: "6px",
              padding: "5px 20px",
              display: "flex",
              gap: "5px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ fontSize: "12px", color: "black" }}>
              انشاء رسالة
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        id="table-container"
        sx={{
          width: "100%",
          borderRadius: "6px",
          overflow: "scroll",
        }}
      >
        <DataGridPro
          sx={{
            border: "none",
            ".muirtl-qvtrhg-MuiDataGrid-virtualScroller": {
              paddingBottom: "200px",
            },
            ".muirtl-204u17-MuiDataGrid-main": {
              minHeight: "500px",
            },
            ".muirtl-axafay-MuiDataGrid-virtualScroller": {
              paddingBottom: "300px",
            },
            "& .MuiDataGrid-columnHeaders *": {
              fontWeight: 700,
            },
            "& .MuiDataGrid-columnHeaders": {
              position: isHeaderFixed ? "fixed" : "relative",
              backgroundColor: "white",
              zIndex: 999,
              top: "0",
            },
            ".MuiDataGrid-row:last-of-type": {
              paddingBottom: "200px !important",
            },
          }}
          columns={columns}
          rows={
            handleIndexData(expressYourselfData?.array)?.length > 0
              ? handleIndexData(expressYourselfData?.array)
              : []
          }
          onColumnWidthChange={(e) => {
            handleColsWidth(e.colDef, setColsWidth, "colsWidth-express");
          }}
          getRowHeight={() => "auto"}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowId={(row: any) => row?.id}
          autoHeight
          loading={isLoading}
          disableRowSelectionOnClick
        />
        <Pagination
          sx={{ margin: "10px 0" }}
          count={Math.ceil(expressYourselfData?.count / limit) || 0}
          onChange={(e, value) => setPage(value)}
          variant="outlined"
          shape="rounded"
        />
      </Box>
      <MessagesModal
        openModal={openMessageModal}
        handleCloseModal={() => setOpenMessageModal(false)}
      />
    </>
  );
};

export default ExpressYourself;
