import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Snackbar,
  TextField,
} from "@mui/material";
import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import styles from "./bulk-edit-program.module.scss";
import { useGetQuery } from "../../../../api/useGetQuery";
import { useEffect, useState } from "react";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { CustomAutoComplete } from "../../../../components/shared/inputs/custom-auto-complete/custom-auto-complete";

const BulkEditProgram = ({ selectedIds }) => {
  const [showValue, setShowValue] = useState<any>({ radioShowName: "" });
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);

  const { data: radioShows, isLoading } = useGetQuery(
    "radio-shows",
    "/radio-shows"
  );

  const { mutateAsync, isError, isSuccess } = useMutateQuery();

  const handleShowChange = () => {
    mutateAsync({
      url: `/messages/bulk/${showValue?.id}`,
      body: {
        ids: Array.from(selectedIds),
      },
      method: "patch",
    });
    setShowValue({ radioShowName: "" });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpen(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setOpenError(true);
    }
  }, [isError]);

  const handleClose = (_, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpen(false);
  };

  return (
    <Box>
      {Array.from(selectedIds)?.length > 0 && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box>
            <CustomAutoComplete
              sx={{ width: "200px" }}
              size="small"
              disablePortal
              options={isLoading ? [] : radioShows?.array ?? []}
              value={showValue}
              onChange={(_, newValue) => {
                setShowValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="اختر البرنامج"
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              getOptionLabel={(option: any) => option?.radioShowName}
            />
          </Box>
          <LightTooltip title="تغيير البرنامج لعدة رسائل">
            <Button onClick={handleShowChange} className={styles.print_button}>
              حفظ
            </Button>
          </LightTooltip>
        </Box>
      )}
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          تم تغيير البرامج بنجاح
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={4000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          فشل في تغيير البرنامج
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BulkEditProgram;
