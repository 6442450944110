import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import styles from "./main-layout.module.scss";

const MainLayout = () => {
  useEffect(() => {
    document.body.classList.add(styles.sidebar_open);

    return () => {
      document.body.classList.remove(styles.sidebar_open);
    };
  }, []);

  const toggleSidebar = () => {
    document.body.classList.toggle(styles.sidebar_open);
  };

  const toggleSidebarOpen = () => {
    if (!document.body.classList.contains(styles.sidebar_open)) {
      document.body.classList.add(styles.sidebar_open_temporary);
    }
  };
  const toggleSidebarClose = () => {
    if (!document.body.classList.contains(styles.sidebar_open)) {
      document.body.classList.remove(styles.sidebar_open_temporary);
    }
  };

  return (
    <>
      <Sidebar
        toggleSidebarOpen={toggleSidebarOpen}
        toggleSidebarClose={toggleSidebarClose}
      />
      <div className={styles.main}>
        <div className={styles.main__content}>
          <TopNav toggleSidebar={toggleSidebar} />
          <div className={styles.main__content__body}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
