export const handleColsWidth = (e, setColsWidth, localStorageName) => {
  const { field, width } = e;

  setColsWidth((prev) => {
    const updatedColsWidth = {
      ...prev,
      [field]: width,
    };

    localStorage.setItem(localStorageName, JSON.stringify(updatedColsWidth));

    return updatedColsWidth;
  });
};
