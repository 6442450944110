import { Autocomplete } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    transition: "0.2s ease-in-out all",

    borderRadius: "6px",
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.background.paper
        : `#000B1808`,
    border: `1px solid ${theme.palette.divider}`,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
    // boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 0.2rem`,
    outline: `0.2rem solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    border: `1px solid #D3302F00`,
    // boxShadow: ` 0 0 0 0.2rem`,
    outline: `0.2rem solid #F005`,
  },

  "& .MuiInputLabel-root": {
    display: "none",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "0.9rem",
    padding: "11px 14px",
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiOutlinedInput-input": {
      fontSize: "0.8rem",
      padding: "10px 12px",
    },
  },
}));
