import { Box } from "@mui/material";
import { createContext, useEffect, useState } from "react";

type User = {
  loggedIn: boolean;
  username?: string;
  role?: string;
  userId?: string;
};

export type GlobalStateContextType = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
};

export const GlobalStateContext = createContext<GlobalStateContextType>({
  user: { loggedIn: false },
  setUser: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  let userInitial: string | null = localStorage.getItem("user");
  let authentication: string | null = localStorage.getItem("token");

  const [user, setUser] = useState<User>({
    loggedIn: false,
    username: "",
    role: "",
  });

  useEffect(() => {
    if (authentication) {
      setUser({
        loggedIn: true,
        username: JSON.parse(userInitial).username,
      });
    } else {
      setUser({
        loggedIn: false,
        username: "",
        role: "",
      });
    }
  }, [userInitial, authentication]);

  if (authentication && user.loggedIn == false) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        Loading ...
      </Box>
    );
  }

  return (
    <GlobalStateContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default AuthProvider;
