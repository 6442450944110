import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sidebarNav from "../../configs/sidebarNav";
import {
  GlobalStateContext,
  GlobalStateContextType,
} from "../../context/GlobalStateContext";
import "./sidebar.scss";

interface SidebarTypes {
  toggleSidebarOpen: () => void;
  toggleSidebarClose: () => void;
}

const Sidebar: React.FC<SidebarTypes> = (props) => {
  const { toggleSidebarOpen, toggleSidebarClose } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const { setUser } = useContext<GlobalStateContextType>(GlobalStateContext);

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex(
      (item: { section: string }) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const logout = () => {
    setUser({ loggedIn: false, role: "", username: "" });
    navigate("/login");
    localStorage.clear();
  };

  return (
    <div
      className="sidebar"
      onMouseEnter={toggleSidebarOpen}
      onMouseLeave={toggleSidebarClose}
    >
      <div className="sidebar__logo">
        <h3>راديو الغد</h3>
      </div>
      <div className="sidebar__menu">
        <div className="sidebar__menu__list">
          {sidebarNav.map((nav, index) => {
            return (
              <Link
                to={nav.link}
                key={`nav-${index}`}
                className={`sidebar__menu__list__item ${
                  activeIndex === index && "active"
                }`}
              >
                <div className="sidebar__menu__list__item__icon">
                  {nav.icon}
                </div>
                <div className="sidebar__menu__list__item__txt">{nav.text}</div>
              </Link>
            );
          })}
        </div>
        <div onClick={() => logout()} className="logout">
          <div className="logout__txt">تسجيل الخروج</div>
          <div className="logout__icon">
            <i
              style={{ color: "#fd523e" }}
              className="bx bx-log-out-circle"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
