import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import { Box } from "@mui/material";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { useEffect, useState } from "react";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";

const IsLiveReadCell = ({ params, index }) => {
  const [isMessageLiveRead, setIsMessageLiveRead] = useState(
    params?.is_live_read
  );

  const { mutateAsync, isError } = useMutateQuery();

  const handleLiveReadChange = (value) => {
    setIsMessageLiveRead(value);
    mutateAsync({
      url: `/messages/${params?.id}`,
      body: {
        isLiveRead: value,
      },
      method: "patch",
    });
  };

  useEffect(() => {
    setIsMessageLiveRead(params?.is_live_read);
  }, [params?.is_live_read]);

  useEffect(() => {
    if (isError) {
      setIsMessageLiveRead(params?.is_live_read);
    }
  }, [isError]);

  return (
    <LightTooltip title={"تعيين كمقروءة مباشر"}>
      {isMessageLiveRead ? (
        <Box
          sx={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            color: "#F9FBF8",
            padding: "4px 4px 1px 4px",
            backgroundColor: "#62C16C",
            border: "1px solid #62C16C",
            borderRadius: "6px",
            width: "25px",
            height: "25px",
          }}
          onClick={() => handleLiveReadChange("false")}
        >
          {index}

          {/* <AddTaskOutlinedIcon sx={{ fontSize: "16px" }} /> */}
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            color: "#51b05b",
            padding: "4px 4px 1px 4px",
            backgroundColor: "#F8FBFA",
            border: "1px solid #62C16C",
            borderRadius: "6px",
            width: "25px",
            height: "25px",
          }}
          onClick={() => handleLiveReadChange("true")}
        >
          {index}
          {/* <AddTaskOutlinedIcon sx={{ fontSize: "16px" }} /> */}
        </Box>
      )}
    </LightTooltip>
  );
};

export default IsLiveReadCell;
