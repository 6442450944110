import { Box, Fade, LinearProgress, Menu, Pagination } from "@mui/material";
import React, { useState } from "react";
import { CustomNoRowsOverlay } from "../../components/shared/ui/custom-no-row-overly/custom-no-row-overly";
import { handleColsWidth } from "../../utils/handle-cols-width";
import { handleIndexData } from "../../utils/handle-index-data";
import { DataGridPro } from "@mui/x-data-grid-pro";
import BasicRangeShortcuts from "../../components/shared/inputs/date-range-picker-mui/CustomShortcutItem";
import DateRangePickerMui from "../../components/shared/inputs/date-range-picker-mui/DateRangePickerMui";
import { formatISODate } from "../../utils/format-date";
import useFixedHeader from "../../hooks/useFixedHeader";
import { useGetQuery } from "../../api/useGetQuery";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";

const Entreaties = () => {
  const limit = 50;
  const pageScrollPosition = 280;
  const [page, setPage] = useState(1);

  const [colsWidth, setColsWidth] = useState(() => {
    const savedColsWidth = JSON.parse(
      localStorage.getItem("colsWidth-reporter")
    );
    return (
      savedColsWidth || {
        content: 500,
        client: 200,
        location: 300,
        date: 150,
        directorate: 150,
        media: 100,
      }
    );
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const today = dayjs();
  const currentMonthRange: DateRange<Dayjs> = [
    today.add(3, "hours"),
    today.add(3, "hours"),
  ];

  const [startDate, setStartDate] = useState<string>(
    currentMonthRange[0].toISOString()
  );
  const [endDate, setEndDate] = useState<string>(
    currentMonthRange[1].toISOString()
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateRange = (e: DateRange<Dayjs>) => {
    setStartDate(e[0]?.toISOString());
    setEndDate(e[1]?.toISOString());
  };

  const { data, isLoading } = useGetQuery(
    `entreaties-${limit}-${page}${startDate}-${endDate}`,
    "/entreaties?relations[]=message.platform.client&relations[]=message.platform.client.phones&relations[]=directorate",
    {
      limit: limit,
      offset: page * limit - limit,
      startDate: startDate,
      endDate: endDate,
    }
  );

  const { isHeaderFixed } = useFixedHeader({ pageScrollPosition });

  const columns: any[] = [
    {
      field: "message.content",
      headerName: "الرسائل",
      width: colsWidth?.content,
      flex: colsWidth?.content === 500 ? 1 : 0,
      renderCell: (params) => {
        return <Box sx={{ padding: "10px 0" }}>{params?.row?.content}</Box>;
      },
    },
    {
      field: "message.platform.client.fullName",
      headerName: "الاسم",
      width: colsWidth?.client,
      renderCell: (params) => {
        return <Box>{params?.row?.fullName}</Box>;
      },
    },
    {
      field: "location",
      headerName: "المنطقة",
      width: colsWidth?.location,
      renderCell: (params) => {
        let neighborhood =
          data?.locations &&
          data?.locations[params?.row?.id]?.neighborhood_name;
        let subCity =
          data?.locations && data?.locations[params?.row?.id]?.sub_city_name;
        let city =
          data?.locations && data?.locations[params?.row?.id]?.city_name;

        if (subCity && city && neighborhood) {
          return <Box>{`${city} / ${subCity} / ${neighborhood}`}</Box>;
        } else if (subCity && city) {
          return <Box>{`${subCity} / ${city}`}</Box>;
        } else if (neighborhood) {
          return <Box>{neighborhood}</Box>;
        } else if (subCity) {
          return <Box>{subCity}</Box>;
        } else if (city) {
          return <Box>{city}</Box>;
        } else {
          return <Box>لا يوجد</Box>;
        }
      },
    },
    {
      field: "directorateName",
      headerName: "المديرية",
      width: colsWidth?.directorate,
      renderCell: (params) => {
        return <Box>{params?.row?.directorateName}</Box>;
      },
    },
    {
      field: "createdAt",
      headerName: "وقت الارسال",
      width: colsWidth?.date,
      renderCell: (params) => {
        return <Box>{formatISODate(params?.row?.createdAt)}</Box>;
      },
    },
  ];

  return (
    <>
      <Box
        component="section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <div style={{ fontSize: "22px" }}>انا مراسل</div>
        <DateRangePickerMui
          startDate={startDate}
          endDate={endDate}
          handleClick={handleClick}
        />
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <BasicRangeShortcuts
            handleDateRange={handleDateRange}
            startDate={startDate}
            endDate={endDate}
          />
        </Menu>
      </Box>
      <Box
        id="table-container"
        sx={{
          width: "100%",
          borderRadius: "6px",
          overflow: "scroll",
        }}
      >
        <DataGridPro
          sx={{
            border: "none",
            ".muirtl-qvtrhg-MuiDataGrid-virtualScroller" :{
              paddingBottom: "200px",
            },
            ".muirtl-204u17-MuiDataGrid-main": {
              minHeight: "500px",
            },
            ".muirtl-axafay-MuiDataGrid-virtualScroller": {
              paddingBottom: "300px",
            },
            "& .MuiDataGrid-columnHeaders *": {
              fontWeight: 700,
            },
            "& .MuiDataGrid-columnHeaders": {
              position: isHeaderFixed ? "fixed" : "relative",
              backgroundColor: "white",
              zIndex: 999,
              top: "0",
            },
            ".MuiDataGrid-row:last-of-type": {
              paddingBottom: "200px !important",
            },
          }}
          columns={columns}
          rows={
            handleIndexData(data?.array)?.length > 0
              ? handleIndexData(data?.array)
              : []
          }
          onColumnWidthChange={(e) => {
            handleColsWidth(e.colDef, setColsWidth, "entreaties");
          }}
          getRowHeight={() => "auto"}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowId={(row: any) => row?.id}
          autoHeight
          loading={isLoading}
          disableRowSelectionOnClick
        />
        <Pagination
          sx={{ marginTop: "10px" }}
          count={Math.ceil(data?.count / limit) || 0}
          onChange={(e, value) => setPage(value)}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </>
  );
};

export default Entreaties;
