import { useState } from "react";
import { Box, Button, FormLabel, MenuItem, Typography } from "@mui/material";
import CustomModal from "../../../../components/shared/ui/custom-modal/custom-modal";
import { CustomSelect } from "../../../../components/shared/inputs/custom-select/custom-select";
import { CustomTextField } from "../../../../components/shared/inputs/input/BootStrapInput";
import QrForm from "./qr-form";
import WhatsupQrCode from "./whats-up-qr-code";

type WhatsUpQrModalTypes = {
  openModal: boolean;
  handleCloseModal: () => void;
  qrData: string;
};

const WhatsUpQrModal: React.FC<WhatsUpQrModalTypes> = (props) => {
  const { openModal, handleCloseModal, qrData } = props;

  const [isQrShown, setIsQrShown] = useState(false);

  const closeModal = () => {
    handleCloseModal();
    setIsQrShown(false);
  };

  return (
    <CustomModal
      customStyle={{ width: "auto", maxWidth: 500 }}
      openModal={openModal}
      handleCloseModal={closeModal}
    >
      {isQrShown ? (
        <WhatsupQrCode qrData={qrData} />
      ) : (
        <QrForm setIsQrShown={setIsQrShown} />
      )}
    </CustomModal>
  );
};

export default WhatsUpQrModal;
