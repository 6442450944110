import "boxicons";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./auth/ProtectedRoutes";
import Login from "./features/login/Login";
import Messages from "./features/messages/Messages";
import MainLayout from "./layout/MainLayout";
import LiveMessages from "./features/live-messages/live-messages";
import ProgramMessages from "./features/messages/program-messages/program-messages";
import Reporter from "./features/reporters/reporters";
import ExpressYourself from "./features/express-yourself/express-yourself";
import "./scss/App.scss";
import Entreaties from "./features/entreaties/entreaties";
import GroupedMessages from "./features/grouped-messages/grouped-messages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Messages />} />
            <Route
              path="/messages/program-messages/:radioShowId"
              element={<ProgramMessages />}
            />
            <Route path="/live-messages" element={<LiveMessages />} />
            <Route path="/reporter" element={<Reporter />} />
            <Route path="/express-yourself" element={<ExpressYourself />} />
            <Route path="/entreaties" element={<Entreaties />} />
            <Route path="/grouped-messages" element={<GroupedMessages />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
