import { Box } from "@mui/material";
import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { useEffect, useState } from "react";

const GenderCell = ({ params }) => {
  const [clientGender, setClientGender] = useState(params?.client_gender);

  const { mutateAsync, isError } = useMutateQuery();
  
  const handleGenderChange = async (gender) => {
    if (clientGender !== gender) {
      setClientGender(gender);
      await mutateAsync({
        url: `/clients/${params?.client_id}`,
        body: { gender },
        method: "patch",
      });
    }
  };

  useEffect(() => {
    setClientGender(params?.client_gender);
  }, [params?.client_id]);

  useEffect(() => {
    if (isError) {
      setClientGender(params?.client_gender);
    }
  }, [isError]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        flexDirection: "column",
        padding: "5px 0",
      }}
    >
      <LightTooltip title={"ذكر"} placement="right">
        <Box
          sx={{
            textAlign: "center",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            color: clientGender === "male" ? "#F8F9FB" : "#629FC1",
            border: "1px solid #629FC1",
            backgroundColor: clientGender === "male" ? "#629FC1" : "#F8F9FB",
            borderRadius: "6px",
            padding: "2px",
          }}
          onClick={() => handleGenderChange("male")}
        >
          <ManIcon sx={{ fontSize: "20px" }} />
        </Box>
      </LightTooltip>
      <LightTooltip title={"انثى"} placement="right">
        <Box
          sx={{
            textAlign: "center",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            color: clientGender === "female" ? "#F8F9FB" : "#BF62C1",
            border: "1px solid #BF62C1",
            backgroundColor: clientGender === "female" ? "#BF62C1" : "#F8F9FB",
            borderRadius: "6px",
            padding: "2px",
          }}
          onClick={() => handleGenderChange("female")}
        >
          <WomanIcon sx={{ fontSize: "20px" }} />
        </Box>
      </LightTooltip>
    </Box>
  );
};

export default GenderCell;
