import { useState } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import { DataGridPro, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import ActionsModal from "../actions-model/actions-model";
import { CustomNoRowsOverlay } from "../../../../components/shared/ui/custom-no-row-overly/custom-no-row-overly";
import EditableShowName from "../editable-show-name/editable-show-name";
import styles from "./table-messages.module.scss";
import PreviousMessages from "../previous-messages/previous-messages";
import IsLiveReadCell from "../table-cells/is-live-read-cell";
import MessageContentCell from "../table-cells/message-content-cell";
import SequenceCell from "../table-cells/sequence-cell";
import ActionsCell from "../table-cells/actions-cell";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import EditableClientLocation from "../editable-client-location/editable-client-location";
import ClientNameCell from "../table-cells/client-name-cell";
import { useGetQuery } from "../../../../api/useGetQuery";
import { handleIndexData } from "../../../../utils/handle-index-data";
import { handleColsWidth } from "../../../../utils/handle-cols-width";
import useFixedHeader from "../../../../hooks/useFixedHeader";

const TableMessages = (props) => {
  const {
    hasNameFilter,
    hiddenFilter,
    startDate,
    endDate,
    isSeenFilter,
    setSelectedIds,
    globalMessagesSearch,
  } = props;

  const isScreenLarge = useMediaQuery("(min-width: 1500px)");

  let limit = 50;
  let pageScrollPosition = 360;
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPrevMessageModal, setOpenPrevMessageModal] =
    useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");

  const [colsWidth, setColsWidth] = useState(() => {
    const savedColsWidth = JSON.parse(localStorage.getItem("colsWidth"));
    return (
      savedColsWidth || {
        is_live_read: 10,
        content: 500,
        full_name: 135,
        region: 60,
        radio_show_name: 70,
        sequence: 65,
        actions: 80,
      }
    );
  });

  const { data: genderCount } = useGetQuery(
    `locations-${startDate}-${endDate}`,
    "/messages/gender-count",
    {
      startDate: startDate,
      endDate: endDate,
      isLiveRead: isSeenFilter === "all" ? undefined : isSeenFilter,
    }
  );

  const { data, isLoading } = useGetQuery(
    `messages-${globalMessagesSearch}-${hasNameFilter}-${hiddenFilter}-${startDate}-${endDate}-${isSeenFilter}-${limit}-${page}`,
    "/messages",
    {
      q: globalMessagesSearch,
      name: hasNameFilter === "hasName" ? undefined : hasNameFilter,
      isDeleted: hiddenFilter,
      startDate: startDate,
      endDate: endDate,
      isLiveRead: isSeenFilter === "all" ? undefined : isSeenFilter,
      limit: limit,
      offset: page * limit - limit,
    }
  );

  const { mutateAsync } = useMutateQuery();

  const { isHeaderFixed } = useFixedHeader({ pageScrollPosition });

  const columns: any[] = [
    {
      field: "is_live_read",
      headerName: "مقروءة",
      width: colsWidth?.is_live_read,
      renderCell: (params) => {
        return <IsLiveReadCell params={params?.row} index={params.row.index} />;
      },
    },
    {
      field: "content",
      headerName: "الرسائل",
      width: colsWidth?.content,
      flex: colsWidth?.content === 500 ? 1 : 0,
      renderCell: (params) => {
        return (
          <MessageContentCell
            params={params?.row}
            setOpenPrevMessageModal={setOpenPrevMessageModal}
            setClientId={setClientId}
          />
        );
      },
    },
    {
      field: "full_name",
      headerName: "الاسم",
      type: "text",
      width: colsWidth?.full_name,
      flex: colsWidth?.full_name === 135 ? 0.3 : 0,
      renderCell: (params) => {
        return <ClientNameCell params={params} />;
      },
      valueGetter: (params) => {
        return params?.row?.full_name;
      },
    },
    {
      field: "region",
      headerName: "المنطقة",
      editable: true,
      width: colsWidth?.region,
      flex: colsWidth.region === 60 ? 0.3 : 0,
      renderCell: (params) => {
        let neighborhood =
          data?.locations &&
          data?.locations[params?.row?.id]?.neighborhood_name;
        let subCity =
          data?.locations && data?.locations[params?.row?.id]?.sub_city_name;
        let city =
          data?.locations && data?.locations[params?.row?.id]?.city_name;

        if (subCity && city && neighborhood) {
          return (
            <Box
              sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}
            >{`${city} / ${subCity} / ${neighborhood}`}</Box>
          );
        } else if (subCity && city) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              {`${city} / ${subCity}`}
            </Box>
          );
        } else if (neighborhood) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              {neighborhood}
            </Box>
          );
        } else if (subCity) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              {subCity}
            </Box>
          );
        } else if (city) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>{city}</Box>
          );
        } else {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              لا يوجد
            </Box>
          );
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <EditableClientLocation
          params={params?.row}
          locationsHashMap={data?.locations}
        />
      ),
    },
    {
      field: "radio_show_name",
      headerName: "البرنامج",
      editable: true,
      type: "text",
      width: colsWidth?.radio_show_name,
      flex: colsWidth.radio_show_name === 70 ? 0.3 : 0,
      renderCell: (params) => {
        return (
          <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
            {params?.row?.radio_show_name || "لا يوجد"}
          </Box>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <EditableShowName data={params?.row} />
      ),
    },
    {
      field: "sequence",
      headerName: "المعلومات",
      width: colsWidth?.sequence,
      renderCell: (params) => {
        return <SequenceCell params={params?.row} />;
      },
      valueGetter: (params) => {
        return +params?.row?.sequence;
      },
    },
    {
      field: "actions",
      headerName: "الاجراءات",
      width: colsWidth?.actions,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0px" }}>
            <ActionsCell params={params?.row} />
            <IconButton
              onClick={() => {
                setOpenModal(true);
                setMessageId(params?.row?.id);
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleCellClick: any = (params) => {
    if (params.field === "content") {
      if (params?.row?.is_live_read) {
        mutateAsync({
          url: `/messages/${params?.row?.id}`,
          body: {
            isLiveRead: "false",
          },
          method: "patch",
        });
      } else {
        mutateAsync({
          url: `/messages/${params?.row?.id}`,
          body: {
            isLiveRead: "true",
          },
          method: "patch",
        });
      }
    }
  };

  return (
    <>
      <Box
        id="table-container"
        sx={{
          width: "100%",
          borderRadius: "6px",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            textAlign: "right",
            fontSize: "14px",
            marginRight: "15px",
            color: "#2E73FF",
          }}
        >
          (الاناث {genderCount?.femaleCount}) - (الذكور {genderCount?.maleCount}
          ) - (غير محدد {genderCount?.noneCount}) - (عدد الرسائل{" "}
          {+genderCount?.femaleCount +
            +genderCount?.maleCount +
            +genderCount?.noneCount}
          )
        </Box>
        <DataGridPro
          sx={{
            border: "none",
            ".muirtl-qvtrhg-MuiDataGrid-virtualScroller" :{
              paddingBottom: "200px",
            },
            ".muirtl-204u17-MuiDataGrid-main": {
              minHeight: "500px",
            },
            ".muirtl-axafay-MuiDataGrid-virtualScroller": {
              paddingBottom: "300px",
            },
            "& .MuiDataGrid-columnHeaders *": {
              fontWeight: 700,
            },
            "& .MuiDataGrid-columnHeaders": {
              position: isHeaderFixed ? "fixed" : "relative",
              backgroundColor: "white",
              zIndex: 999,
              top: "0",
            },
            ".MuiDataGrid-row:last-of-type": {
              paddingBottom: "200px !important",
            },
          }}
          columns={columns}
          rows={
            handleIndexData(data?.array)?.length > 0
              ? handleIndexData(data?.array)
              : []
          }
          onColumnWidthChange={(e) => {
            handleColsWidth(e.colDef, setColsWidth, "colsWidth");
          }}
          getRowHeight={() => "auto"}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowId={(row: any) => row?.id}
          autoHeight
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(ids: any) => {
            const selectedIDs = new Set(ids);
            setSelectedIds(selectedIDs);
          }}
          getRowClassName={(params: any) =>
            `${params?.row?.is_live_read ? styles.is_seen_row_true : ""}`
          }
          onCellDoubleClick={handleCellClick}
        />
      </Box>
      <Pagination
        sx={{ marginTop: "10px" }}
        count={Math.ceil(data?.count / limit) || 0}
        onChange={(e, value) => setPage(value)}
        variant="outlined"
        shape="rounded"
      />
      {openModal && (
        <ActionsModal
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
          messageId={messageId}
        />
      )}
      {openPrevMessageModal && (
        <PreviousMessages
          openModal={openPrevMessageModal}
          handleCloseModal={() => {
            setOpenPrevMessageModal(false);
            setClientId("");
          }}
          startDate={startDate}
          endDate={endDate}
          clientId={clientId}
        />
      )}
    </>
  );
};

export default TableMessages;
