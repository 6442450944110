import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import EditableClientName from "../editable-client-name/editable-client-name";

const ClientNameCell = ({ params }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [clientName, setClientName] = useState(params?.row?.full_name);

  const isScreenLarge = useMediaQuery("(min-width: 1500px)");

  useEffect(() => {
    setClientName(params?.row?.full_name);
  }, [params?.row?.full_name]);

  return (
    <>
      {isEditable ? (
        <EditableClientName
          {...params}
          setIsEditable={setIsEditable}
          clientName={clientName}
          setClientName={setClientName}
        />
      ) : (
        <Box
          onDoubleClick={() => setIsEditable(true)}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {clientName ? (
            <Box
              sx={{
                overflow: "hidden",
                fontSize: isScreenLarge ? "16px" : "14px",
              }}
            >
              {clientName}
            </Box>
          ) : (
            <Box
              sx={{
                overflow: "hidden",
                fontSize: isScreenLarge ? "16px" : "14px",
                direction: "rtl",
              }}
            >
              {params?.row?.phone_number?.slice(0, -2)
                ? params?.row?.phone_number?.slice(0, -2) + "xx"
                : "لا يوجد"}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ClientNameCell;
