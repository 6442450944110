import { Breadcrumbs } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import user from "../../assets/user.webp";
import "./topnav.scss";

type TopNavTypes = {
  toggleSidebar: () => void;
};

const TopNav: React.FC<TopNavTypes> = ({ toggleSidebar }) => {
  const location = useLocation();
  const [iconDirection, setIconDirection] = useState(false);

  const handleSidebarClick = () => {
    setIconDirection(!iconDirection);
    toggleSidebar();
  };

  // const curPath = location.pathname.split("/")[1];

  // const headerView = (header: string) => {
  //   if (header === "") return "ادارة الرسائل";
  //   header = header.charAt(0).toUpperCase() + header.slice(1).toLowerCase();
  //   header = header.replace("-", " ");

  //   return header;
  // };

  return (
    <div className="topnav">
      <div className="sidebar-toggle" onClick={handleSidebarClick}>
        {!iconDirection ? (
          <i className="bx bx-chevron-right"></i>
        ) : (
          <i className="bx bx-chevron-left"></i>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {/* {headerView(curPath)} */}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {/* {Object.entries(routesConfig[location?.pathname]).map(
            ([key, value]: any) => {
              return (
                <Link key={key} to={key}>
                  {value}
                </Link>
              );
            }
          )} */}
        </Breadcrumbs>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <img
            style={{
              width: "37px",
              height: "37px",
              aspectRatio: 1 / 1,
            }}
            src={user}
            alt="alt"
          />
          {/* <div>
            <h5 className="nav-user-name">محمد العبد</h5>
            <h5 className="nav-user-position">مدير التسويق الرقمي </h5>
          </div> */}
          <div>
            <i className="bx bx-chevron-down"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
