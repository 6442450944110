import { useState } from "react";
import { Box, Button, Menu } from "@mui/material";
import styles from "./replay-drop-menu.module.scss";
import { CustomTextarea } from "../../../../components/shared/inputs/custom-text-area/custom-text-area";
import { useMutateQuery } from "../../../../api/useMutateQuery";

const ReplayDropMenu = ({ data }) => {
  const [replay, setReplay] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { mutateAsync } = useMutateQuery();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitReplay = async () => {
    await mutateAsync({
      url: `/messages/send`,
      body: {
        content: replay,
        platformId: data?.platform_id,
        port: data?.port,
      },
      method: "post",
    });
    handleClose();
    setReplay("");
  };

  return (
    <Box className={styles.replay_drop_menu_parent}>
      <Button
        sx={{
          fontSize: "12px",
          padding: "1px 8px",
          backgroundColor: "#F8FBFA",
          border: "1px solid #62C16C",
          borderRadius: "6px",
          minWidth: "0",
          color: "black",
          "&:hover": {
            backgroundColor: "#F8FBFA",
          },
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        رد
      </Button>
      <Menu
        className={styles.menu_roles_stats}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "300px",
            padding: "10px 10px 10px 10px",
          },
        }}
      >
        <CustomTextarea
          onChange={(e) => setReplay(e.target.value)}
          placeholder="اكتب الرد هنا"
          minRows={2}
          sx={{ width: "100%", marginBottom: "10px" }}
          value={replay}
        />
        <Button className={styles.send_button} onClick={() => submitReplay()}>
          ارسال
        </Button>
      </Menu>
    </Box>
  );
};

export default ReplayDropMenu;
