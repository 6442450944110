import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomSelect = styled(Select)(({ theme }) => ({
  "&>*": {
    border: "none !important",
  },
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#000B1808",
    border: "none !important",
    borderColor: "rgba(0, 11, 24, 0.03)",
    fontSize: 14,
    width: "100%",
    padding: "10px 14px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: "0 0 0 3px rgba(68, 61, 246, 0.26)",
      borderColor: "#443DF6",
      borderRadius: 6,
      border: "1px solid #443DF6 !important",
    },
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiInputBase-input": {
      fontSize: "0.75rem",
      padding: "6px 10px",
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiInputBase-input": {
      fontSize: "0.625rem",
      padding: "6px 8px",
      height: "10px",
    },
  },
}));
