import { useEffect } from "react";

const BrowserTabTitle = ({ children, title }) => {
  useEffect(() => {
    window.document.title = `${title}`;
  }, [children]);

  return <>{children}</>;
};

export default BrowserTabTitle;
