import { useEffect, useMemo, useState } from "react";
import { Box, IconButton, LinearProgress, useMediaQuery } from "@mui/material";
import { DataGridPro, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import EditableClientName from "../../components/editable-client-name/editable-client-name";
import EditableClientLocation from "../../components/editable-client-location/editable-client-location";
import ActionsModal from "../../components/actions-model/actions-model";
import { CustomNoRowsOverlay } from "../../../../components/shared/ui/custom-no-row-overly/custom-no-row-overly";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import EditableShowName from "../../components/editable-show-name/editable-show-name";
import styles from "../../components/table-messages/table-messages.module.scss";
import PreviousMessages from "../../components/previous-messages/previous-messages";
import IsLiveReadCell from "../../components/table-cells/is-live-read-cell";
import MessageContentCell from "../../components/table-cells/message-content-cell";
import GenderCell from "../../components/table-cells/gender-cell";
import SequenceCell from "../../components/table-cells/sequence-cell";
import MessagePlacementCell from "../../components/table-cells/message-placement-cell";
import ActionsCell from "../../components/table-cells/actions-cell";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { api } from "../../../../api/interceptor";
import { useInfiniteQuery } from "react-query";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import ClientNameCell from "../../components/table-cells/client-name-cell";
import { useGetQuery } from "../../../../api/useGetQuery";

const fetchMessages = async ({
  pageParam = 0,
  hasNameFilter,
  hiddenFilter,
  startDate,
  endDate,
  isSeenFilter,
  globalMessagesSearch,
  radioShowId,
}: any) => {
  const limit = 20;
  const response = await api.get(`/messages`, {
    params: {
      q: globalMessagesSearch,
      name: hasNameFilter === "hasName" ? undefined : hasNameFilter,
      isDeleted: hiddenFilter,
      startDate: startDate,
      endDate: endDate,
      isLiveRead: isSeenFilter === "all" ? undefined : isSeenFilter,
      limit: limit,
      offset: pageParam,
      radioShowId: radioShowId,
    },
  });
  return response.data;
};

const ProgramMessagesTable = (props) => {
  const {
    hasNameFilter,
    hiddenFilter,
    startDate,
    endDate,
    isSeenFilter,
    setSelectedIds,
    globalMessagesSearch,
    radioShowId,
  } = props;

  const isScreenLarge = useMediaQuery("(min-width: 1500px)");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPrevMessageModal, setOpenPrevMessageModal] =
    useState<boolean>(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [messageId, setMessageId] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [locationsHashMap, setLocationsHashMap] = useState<any>();

  const [colsWidth, setColsWidth] = useState(() => {
    const savedColsWidth = JSON.parse(localStorage.getItem("colsWidth"));
    return (
      savedColsWidth || {
        is_live_read: 10,
        content: 500,
        full_name: 135,
        region: 60,
        radio_show_name: 70,
        // client_gender: 10,
        sequence: 65,
        // messagePlacement: 30,
        actions: 80,
        // more: 10,
      }
    );
  });

  const { data: genderCount } = useGetQuery(
    `locations-${startDate}-${endDate}`,
    "/messages/gender-count",
    {
      startDate: startDate,
      endDate: endDate,
      isLiveRead: isSeenFilter === "all" ? undefined : isSeenFilter,
    }
  );

  const handleColsWidth = (e) => {
    const { field, width } = e;

    setColsWidth((prev) => {
      const updatedColsWidth = {
        ...prev,
        [field]: width,
      };

      localStorage.setItem("colsWidth", JSON.stringify(updatedColsWidth));

      return updatedColsWidth;
    });
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching } =
    useInfiniteQuery(
      [
        "messages",
        hasNameFilter,
        hiddenFilter,
        startDate,
        endDate,
        isSeenFilter,
        globalMessagesSearch,
        radioShowId,
      ],
      ({ pageParam }) =>
        fetchMessages({
          pageParam,
          hasNameFilter,
          hiddenFilter,
          startDate,
          endDate,
          isSeenFilter,
          globalMessagesSearch,
          radioShowId,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const totalCount = lastPage.count;
          const totalPages = Math.ceil(totalCount / 20);
          const currentPage = allPages.length;
          return currentPage < totalPages ? currentPage * 20 : null;
        },
      }
    );

  const { mutateAsync } = useMutateQuery();

  const flattenPages = (pages) => {
    const messagesData = pages?.flatMap((page) => page.array) || [];

    const messagesDataWithIndex = messagesData?.map((row, index) => ({
      ...row,
      index: index + 1, // Add 1 to start the index from 1
    }));

    return messagesDataWithIndex;
  };

  const locationsData = useMemo(() => {
    return data?.pages?.flatMap((page) => page.locations) || [];
  }, [data]);

  useEffect(() => {
    setLocationsHashMap((prev) => {
      const updatedLocationsHashMap = {
        ...prev,
        ...locationsData,
      };

      return updatedLocationsHashMap[0];
    });
  }, [locationsData]);

  useEffect(() => {
    function handleScroll() {
      const totalHeight = document.documentElement.scrollHeight;

      const scrollPosition = window.innerHeight + window.scrollY;

      if (scrollPosition >= totalHeight - 40) {
        if (hasNextPage) {
          fetchNextPage();
        }
      }
    }

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [hasNextPage]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 300) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    });
  }, []);

  const columns: any[] = [
    {
      field: "is_live_read",
      headerName: "مقروءة",
      width: colsWidth?.is_live_read,
      renderCell: (params) => {
        return <IsLiveReadCell params={params?.row} index={params.row.index} />;
      },
    },
    {
      field: "content",
      headerName: "الرسائل",
      width: colsWidth?.content,
      flex: colsWidth?.content === 500 ? 1 : null,
      renderCell: (params) => {
        return (
          <MessageContentCell
            params={params?.row}
            setOpenPrevMessageModal={setOpenPrevMessageModal}
            setClientId={setClientId}
          />
        );
      },
    },
    {
      field: "full_name",
      headerName: "الاسم",
      type: "text",
      width: colsWidth?.full_name,
      flex: colsWidth?.full_name === 135 ? 0.3 : 0,
      renderCell: (params) => {
        return <ClientNameCell params={params} />;
      },
      valueGetter: (params) => {
        return params?.row?.full_name;
      },
    },
    {
      field: "region",
      headerName: "المنطقة",
      editable: true,
      width: colsWidth?.region,
      flex: colsWidth?.region === 60 ? 0.3 : 0,
      renderCell: (params) => {
        let neighborhood =
          locationsHashMap &&
          locationsHashMap[params?.row?.id]?.neighborhood_name;
        let subCity =
          locationsHashMap && locationsHashMap[params?.row?.id]?.sub_city_name;
        let city =
          locationsHashMap && locationsHashMap[params?.row?.id]?.city_name;

        if (subCity && city && neighborhood) {
          return (
            <Box
              sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}
            >{`${city} / ${subCity} / ${neighborhood}`}</Box>
          );
        } else if (subCity && city) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              {`${subCity} / ${city}`}
            </Box>
          );
        } else if (neighborhood) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              {neighborhood}
            </Box>
          );
        } else if (subCity) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              {subCity}
            </Box>
          );
        } else if (city) {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>{city}</Box>
          );
        } else {
          return (
            <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
              لا يوجد
            </Box>
          );
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <EditableClientLocation
          params={params?.row}
          locationsHashMap={locationsHashMap}
        />
      ),
      valueGetter: (params) => {
        let region = params?.row?.region_name;
        let city = params?.row?.city_name;
        if (region && city) {
          return <Box>{`${region} / ${city}`}</Box>;
        } else if (region) {
          return <Box>{region}</Box>;
        } else if (city) {
          return <Box>{city}</Box>;
        }
        return "لا يوجد";
      },
    },
    {
      field: "radio_show_name",
      headerName: "البرنامج",
      editable: true,
      type: "text",
      width: colsWidth?.radio_show_name,
      flex: colsWidth.radio_show_name === 70 ? 0.3 : 0,
      renderCell: (params) => {
        return <Box>{params?.row?.radio_show_name || "لا يوجد"}</Box>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <EditableShowName data={params?.row} />
      ),
    },
    // {
    //   field: "client_gender",
    //   headerName: "الجنس",
    //   maxWidth: colsWidth?.client_gender,
    //   renderCell: (params) => {
    //     return <GenderCell params={params?.row} />;
    //   },
    //   valueGetter: (params) => {
    //     return params?.row?.client_gender === "male"
    //       ? "ذكر"
    //       : params?.row?.client_gender === "female"
    //       ? "انثى"
    //       : "غير محدد";
    //   },
    // },
    {
      field: "sequence",
      headerName: "المعلومات",
      width: colsWidth?.sequence,
      renderCell: (params) => {
        return <SequenceCell params={params?.row} />;
      },
      valueGetter: (params) => {
        return +params?.row?.sequence;
      },
    },
    // {
    //   field: "messagePlacement",
    //   headerName: "موقع الرسائل",
    //   width: colsWidth?.messagePlacement,
    //   renderCell: (params) => {
    //     let messagePlacement = params?.row?.platform_type_name;

    //     return <MessagePlacementCell messagePlacement={messagePlacement} />;
    //   },
    //   valueGetter: (params) => {
    //     return params?.row?.platform_type_name;
    //   },
    // },
    {
      field: "actions",
      headerName: "الاجراءات",
      width: colsWidth?.actions,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0px" }}>
            <ActionsCell params={params?.row} />
            <IconButton
              onClick={() => {
                setOpenModal(true);
                setMessageId(params?.row?.id);
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
    // {
    //   field: "more",
    //   headerName: "المزيد",
    //   disableExport: true,
    //   width: colsWidth?.more,
    //   renderCell: (params) => {
    //     return (
    //       <IconButton
    //         onClick={() => {
    //           setOpenModal(true);
    //           setMessageId(params?.row?.id);
    //         }}
    //       >
    //         <MoreVertOutlinedIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ];

  const handleCellClick: any = (params) => {
    if (params.field === "content") {
      if (params?.row?.is_live_read) {
        mutateAsync({
          url: `/messages/${params?.row?.id}`,
          body: {
            isLiveRead: "false",
          },
          method: "patch",
        });
      } else {
        mutateAsync({
          url: `/messages/${params?.row?.id}`,
          body: {
            isLiveRead: "true",
          },
          method: "patch",
        });
      }
    }
  };

  return (
    <>
      <Box
        id="table-container"
        sx={{
          width: "100%",
          borderRadius: "6px",
        }}
      >
        <Box
          sx={{
            textAlign: "right",
            fontSize: "14px",
            marginRight: "15px",
            color: "#2E73FF",
          }}
        >
          (الاناث {genderCount?.femaleCount}) - (الذكور {genderCount?.maleCount}
          ) - (غير محدد {genderCount?.noneCount}) - (عدد الرسائل{" "}
          {+genderCount?.femaleCount +
            +genderCount?.maleCount +
            +genderCount?.noneCount}
          )
        </Box>
        <DataGridPro
          sx={{
            border: "none",
            ".muirtl-qvtrhg-MuiDataGrid-virtualScroller" :{
              paddingBottom: "200px",
            },
            ".muirtl-204u17-MuiDataGrid-main": {
              minHeight: "500px",
            },
            ".muirtl-axafay-MuiDataGrid-virtualScroller": {
              paddingBottom: "300px",
            },
            "& .MuiDataGrid-columnHeaders *": {
              fontWeight: 700,
            },
            "& .MuiDataGrid-columnHeaders": {
              fontWeight: 900,
              position: isHeaderFixed ? "fixed" : "relative",
              top: "0",
              backgroundColor: "white",
              zIndex: 999,
            },
            ".MuiDataGrid-row:last-of-type": {
              paddingBottom: "200px !important",
            },
          }}
          columns={columns}
          rows={
            flattenPages(data?.pages)?.length > 0
              ? flattenPages(data?.pages)
              : []
          }
          onColumnWidthChange={(e) => {
            handleColsWidth(e.colDef);
          }}
          getRowHeight={() => "auto"}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowId={(row: any) => row?.id}
          autoHeight
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(ids: any) => {
            const selectedIDs = new Set(ids);
            setSelectedIds(selectedIDs);
          }}
          getRowClassName={(params: any) =>
            `${params?.row?.is_live_read ? styles.is_seen_row_true : ""}`
          }
          onCellDoubleClick={handleCellClick}
        />
        {isFetching && !isLoading && <LinearProgress />}
      </Box>
      {openModal && (
        <ActionsModal
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
          messageId={messageId}
        />
      )}
      {openPrevMessageModal && (
        <PreviousMessages
          openModal={openPrevMessageModal}
          handleCloseModal={() => {
            setOpenPrevMessageModal(false);
            setClientId("");
          }}
          startDate={startDate}
          endDate={endDate}
          clientId={clientId}
        />
      )}
    </>
  );
};

export default ProgramMessagesTable;
