import { useEffect, useState } from "react";
import { Box, Button, FormLabel, Typography } from "@mui/material";
import CustomModal from "../../../../components/shared/ui/custom-modal/custom-modal";
import { CustomTextarea } from "../../../../components/shared/inputs/custom-text-area/custom-text-area";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import { useGetQuery } from "../../../../api/useGetQuery";

type SearchModalTypes = {
  openModal: boolean;
  handleCloseModal: () => void;
  messageId: string;
};

const ActionsModal: React.FC<SearchModalTypes> = (props) => {
  const { openModal, handleCloseModal, messageId } = props;

  const [notes, setNotes] = useState<string>("");

  const { data } = useGetQuery(
    `message-${messageId}`,
    `/messages/${messageId}`
  );

  const { mutateAsync } = useMutateQuery();

  const submitNotes = async () => {
    await mutateAsync({
      url: `/messages/${messageId}`,
      body: {
        notes: notes,
      },
      method: "patch",
    });

    handleCloseModal();
  };

  useEffect(() => {
    setNotes(data?.notes);
  }, [data?.id]);

  return (
    <CustomModal openModal={openModal} handleCloseModal={handleCloseModal}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        اضافة ملاحظات
      </Typography>
      <br />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box>
          <FormLabel sx={{ fontSize: "14px", fontWeight: 600 }}>
            الملاحظات
          </FormLabel>
          <CustomTextarea
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            value={notes}
            minRows={3}
            sx={{
              width: "100%",
            }}
            placeholder="اكتب ملاحظات حول الرسالة"
          />
        </Box>
        <Button
          sx={{
            backgroundColor: "#2E73FF",
            color: "white",
            padding: "7px 20px",
            "&:hover": {
              backgroundColor: "#2E73FFbb",
              color: "white",
            },
            textAlign: "left",
          }}
          onClick={() => submitNotes()}
        >
          حفظ
        </Button>
      </Box>
    </CustomModal>
  );
};

export default ActionsModal;
