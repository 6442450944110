import { useState } from "react";
import { useSnackbar } from "notistack";
import { Box, Button, FormLabel, Typography } from "@mui/material";
import { CustomTextField } from "../../../../components/shared/inputs/input/BootStrapInput";

const QrForm = ({ setIsQrShown }) => {
  const [password, setPassword] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (password === "xa2w") {
      setIsQrShown(true);
      enqueueSnackbar("تم عرض الكود", { variant: "success" });
    } else {
      enqueueSnackbar("كلمة المرور غير صحيحة", { variant: "error" });
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        عرض كود QR
      </Typography>
      <br />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box>
          <FormLabel
            sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "20px" }}
          >
            كلمة المرور
          </FormLabel>
          <CustomTextField
            sx={{ width: "100%" }}
            placeholder="كلمة المرور "
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Button
          sx={{
            backgroundColor: "#2E73FF",
            color: "white",
            padding: "7px 20px",
            "&:hover": {
              backgroundColor: "#2E73FFbb",
              color: "white",
            },
            textAlign: "left",
          }}
          onClick={handleSubmit}
        >
          عرض
        </Button>
      </Box>
    </>
  );
};

export default QrForm;
