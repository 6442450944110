import { useState, useRef, useEffect } from "react";
import styles from "./custom-audio-player.module.css";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import GetAppIcon from "@mui/icons-material/GetApp"; // Import the download icon from Material-UI
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const AudioPlayer = ({ src }) => {
  // state
  const [isPlaying, setIsPlaying] = useState<any>(false);
  const [duration, setDuration] = useState<any>(0);
  const [currentTime, setCurrentTime] = useState<any>(0);
  const [shouldRender, setShouldRender] = useState(false);

  // references
  const audioPlayer = useRef<any>(); // reference our audio component
  const progressBar = useRef<any>(); // reference our progress bar
  const animationRef = useRef<any>(); // reference the animation

  useEffect(() => {
    const delay = 700; // Set the delay time in milliseconds (e.g., 2000 for 2 seconds)

    const timerId = setTimeout(() => {
      setShouldRender(true);
    }, delay);

    // Clear the timeout if the component unmounts before the delay
    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(+seconds);
    progressBar.current.max = seconds;
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  const calculateTime = (secs) => {
    if (isNaN(secs) || secs === 0) {
      return "00:00";
    }
    const minutes = Math.floor(+secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(+secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );
    setCurrentTime(progressBar.current.value);
  };

  const backThirty = () => {
    progressBar.current.value = Number(progressBar.current.value - 30);
    changeRange();
  };

  const forwardThirty = () => {
    progressBar.current.value = Number(progressBar.current.value + 30);
    changeRange();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = "audio.mp3";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <a href={src} target="_blank" download={src} style={{ width: "70px" }}>
        <FileDownloadOutlinedIcon />
      </a>
      <div className={styles.audioPlayer}>
        <audio ref={audioPlayer} src={src} preload="metadata"></audio>
        {/* <button className={styles.forwardBackward} onClick={backThirty}>
        <ArrowLeftIcon /> 30
      </button> */}
        <button onClick={togglePlayPause} className={styles.playPause}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </button>
        {/* <button className={styles.forwardBackward} onClick={forwardThirty}>
        30 <ArrowRightIcon />
      </button> */}
        {/* current time */}
        <div className={styles.currentTime}>{calculateTime(currentTime)}</div>
        {/* progress bar */}
        <div>
          <input
            type="range"
            className={styles.progressBar}
            defaultValue="0"
            ref={progressBar}
            onChange={changeRange}
          />
        </div>
        <div className={styles.duration}>
          {shouldRender ? calculateTime(duration) : "00:00"}
        </div>
      </div>
    </>
  );
};

export { AudioPlayer };
