import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

export const CustomTextarea = styled(TextareaAutosize)(
  () => `
  transition: 0.2s ease-in-out all;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #E7EBF0;
  background-color: #000B1808;
  resize: vertical;
  outline: 0;
  &:hover {
    border-color: #443DF6;
  }

  &:focus {
    border-color: #443DF6;
    box-shadow: 0 0 0 3px rgba(68, 61, 246, 0.26);
    -webkit-box-shadow: 0 0 0 3px rgba(68, 61, 246, 0.26);
    -moz-box-shadow: 0 0 0 3px rgba(68, 61, 246, 0.26);
  }

  &.error {
    border-color: red;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
    -moz-box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.4);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.75rem;
    padding: 8px 10px;
  }
  
  @media screen and (max-width: 400px) {
    font-size: 0.625rem;
    padding: 6px 8px;
  }
  `
);
