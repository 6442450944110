import { Box, Button, FormControl, FormLabel, Typography } from "@mui/material";
import CustomModal from "../../../components/shared/ui/custom-modal/custom-modal";
import { CustomTextField } from "../../../components/shared/inputs/input/BootStrapInput";
import { useEffect, useState } from "react";
import { useMutateQuery } from "../../../api/useMutateQuery";
import { useSnackbar } from "notistack";
import { useGetQuery } from "../../../api/useGetQuery";

const englishRegex = /[a-zA-Z]/;

function translateErrorMessage(message) {
  const errorTranslations = {
    "pollTitleArb must be a string": "يجب أن يكون السؤال بالعربية ليست رقم",
    "pollTitleArb should not be empty": "يجب ألا يكون السؤال بالعربية فارغًا",
    "pollTitleEng must be a string": "يجب أن يكون السؤال بالإنجليزية ليست رقم",
    "pollTitleEng should not be empty":
      "يجب ألا يكون السؤال بالإنجليزية فارغًا",
    "pollType must be a string": "يجب أن يكون نوع السؤال ليس رقما",
    "pollType should not be empty": "يجب ألا يكون نوع السؤال فارغًا",
    "sequence must be a number string": "يجب أن يكون التسلسل سلسلة رقمية",
    "sequence should not be empty": "يجب ألا يكون التسلسل فارغًا",
    "formId should not be empty": "يجب ألا يكون معرف النموذج فارغًا",
    "date must be a valid ISO 8601 date string":
      "يجب أن يكون التاريخ سلسلة تاريخية صالحة وفقًا لمعيار ISO 8601",
    "date should not be empty": "يجب ألا يكون التاريخ فارغًا",
  };

  return errorTranslations[message] || message;
}

const MessagesModal = (props) => {
  const { openModal, handleCloseModal } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [question, setQuestion] = useState<string>("");
  const [questionEnglish, setQuestionEnglish] = useState<string>("");

  const { mutateAsync, isSuccess } = useMutateQuery();

  const submitQuestion = async () => {
    try {
      await mutateAsync({
        url: `/polls/express-your-opinion`,
        body: {
          sequence: "0",
          pollTitleArb: question,
          pollTitleEng: questionEnglish,
          formId: 7,
          pollType: "file",
          date: new Date(),
        },
        method: "post",
      });
    } catch (error) {
      enqueueSnackbar(
        translateErrorMessage(error?.response?.data?.message[0]),
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(translateErrorMessage("تم انشاء السؤال"), {
        variant: "success",
      });
      handleCloseModal();
    }
  }, [isSuccess]);

  // gorges, song of achilles, achilles calm down

  return (
    <CustomModal openModal={openModal} handleCloseModal={handleCloseModal}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        انشاء سؤال عبر عن رايك
      </Typography>
      <br />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <FormControl>
          <FormLabel>انشاء سؤال</FormLabel>
          <CustomTextField
            placeholder="اكتب السؤال"
            onChange={(e) => setQuestion(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>السؤال بالانجليزي</FormLabel>
          <CustomTextField
            sx={{
              direction: englishRegex.test(questionEnglish) ? "rtl" : "ltr",
            }}
            placeholder="اكتب السؤال بالانكليزي"
            onChange={(e) => setQuestionEnglish(e.target.value)}
          />
        </FormControl>
        <Button
          sx={{
            backgroundColor: "#2E73FF",
            color: "white",
            padding: "7px 20px",
            "&:hover": {
              backgroundColor: "#2E73FFbb",
              color: "white",
            },
            textAlign: "left",
          }}
          onClick={submitQuestion}
        >
          انشاء
        </Button>
      </Box>
    </CustomModal>
  );
};

export default MessagesModal;
