import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  IconButton,
  Snackbar,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GlobalStateContext,
  GlobalStateContextType,
} from "../../context/GlobalStateContext";
import styles from "./login.module.scss";

import { Alert } from "../../components/shared/ui/custom-alert/custom-alert";
import { CustomTextField } from "../../components/shared/inputs/input/BootStrapInput";
import BrowserTabTitle from "../../components/shared/browser-tab-title/browser-tab-title";
import { useMutateQuery } from "../../api/useMutateQuery";
import { schema } from "./schema/LoginSchema";

type LoginFormType = {
  username?: string;
  password?: string;
};

const Login = () => {
  const navigate = useNavigate();
  const { user, setUser } =
    useContext<GlobalStateContextType>(GlobalStateContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormType>({ resolver: yupResolver(schema) });

  const { mutateAsync, isSuccess, data, isError, isLoading } = useMutateQuery();

  const { state } = useLocation();

  useEffect(() => {
    if (user?.loggedIn) {
      navigate(state?.from?.pathname ?? "/");
    }
  }, [user?.loggedIn]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("token", data.accessToken);
      setUser({ loggedIn: true });
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<LoginFormType> = async (data) => {
    setOpenWarning(true);
    await mutateAsync({
      url: "/user-auth",
      body: {
        userName: data?.username,
        password: data?.password,
      },
      method: "post",
    });
  };

  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (isError) {
      setOpen(true);
    }
  }, [isError]);

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <BrowserTabTitle title={"Login"}>
      <div className={`${styles.login__page} row`}>
        <div className={`col-6 ${styles.right_side}`}>
          <div
            style={{
              backgroundColor: "black",
              opacity: "0.3",
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div>
        <div className={`${styles.left_side} col-6 col-md-12`}>
          <section className={`${styles.containter__form}`}>
            {/* <img
              style={{ width: "180px" }}
              className="logo__login"
              src={logo}
              alt="PTR"
            /> */}
            <div
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#888",
                marginBottom: "70px",
              }}
            >
              مرحبا بك, من فضلك ادخل المعلومات
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <label
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    fontWeight: "700",
                  }}
                  htmlFor="bootstrap-input1"
                >
                  اسم المستخدم
                  <span style={{ color: "red" }}>*</span>
                </label>
                <CustomTextField
                  className={errors?.username?.message && "error"}
                  placeholder="ادخل اسم المستخدم"
                  name="username"
                  type="text"
                  {...register("username")}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.username?.message}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ width: "100%", marginTop: "12px" }}
                variant="standard"
                className={`${styles.password_icon_container}`}
              >
                <label
                  style={{
                    fontSize: "14px",
                    marginBottom: "5px",
                    fontWeight: "700",
                  }}
                  htmlFor="bootstrap-input1"
                >
                  كلمة المرور
                  <span style={{ color: "red" }}>*</span>
                </label>
                <CustomTextField
                  className={errors?.password?.message && "error"}
                  placeholder="ادخل الرمز"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                />
                <IconButton
                  className={styles.password_icon}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityIcon className={styles.show_password_icon} />
                  ) : (
                    <VisibilityOffIcon className={styles.show_password_icon} />
                  )}
                </IconButton>
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.password?.message}
                </FormHelperText>
              </FormControl>
              {isLoading && (
                <>
                  <Snackbar
                    open={openWarning}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="warning"
                      sx={{ width: "100%" }}
                    >
                      انتضار....
                    </Alert>
                  </Snackbar>
                </>
              )}
              <input
                style={{ marginTop: "10px" }}
                type="submit"
                className={styles.submit_btn}
                value="تسجيل الدخول"
              />
              <br />
              <>
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    اسم المستخدم او كلمة المرور غير صحيح
                  </Alert>
                </Snackbar>
              </>
            </form>
          </section>
        </div>
      </div>
    </BrowserTabTitle>
  );
};

export default Login;
