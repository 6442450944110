import { useState } from "react";
import { Box } from "@mui/material";
import styles from "./live-messages.module.scss";
import { CustomTextField } from "../../components/shared/inputs/input/BootStrapInput";
import LiveMessagesTable from "./components/live-messages-table/live-messages";
import BrowserTabTitle from "../../components/shared/browser-tab-title/browser-tab-title";

const LiveMessages = () => {
  const [globalSearch, setGlobalSearch] = useState("");

  return (
    <BrowserTabTitle title="الرسائل المباشرة">
      <Box>
        <Box component="section" className={styles.messages_header_container}>
          <div className={styles.messages_header}>الرسائل المباشرة</div>
          <CustomTextField
            placeholder="البحث"
            sx={{ width: "100%", maxWidth: "200px" }}
            onChange={(e) => setGlobalSearch(e.target.value)}
          />
        </Box>
        <Box
          component="section"
          sx={{
            marginTop: "30px",
          }}
        >
          <LiveMessagesTable globalSearch={globalSearch} />
        </Box>
      </Box>
    </BrowserTabTitle>
  );
};

export default LiveMessages;
