import "./date-range-picker-mui.scss";

type DateRangePickerMuiTypes = {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  startDate: string;
  endDate: string;
};

const DateRangePickerMui: React.FC<DateRangePickerMuiTypes> = ({
  startDate = "DD/MM/YYYY",
  endDate = "DD/MM/YYYY",
  handleClick,
}) => {
  return (
    <div className="input-range" onClick={handleClick}>{`${startDate?.slice(
      0,
      10
    )} - ${endDate?.slice(0, 10)}`}</div>
  );
};

export default DateRangePickerMui;
