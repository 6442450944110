import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import ReplayDropMenu from "../replay-drop-menu/replay-drop-menu";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import MessagePlacementCell from "./message-placement-cell";
import GenderCell from "./gender-cell";

const SequenceCell = ({ params }) => {
  // const [sequence, setSequence] = useState(params?.sequence);

  const { mutateAsync, isError } = useMutateQuery();

  // const handleSequenceChange = () => {
  //   setSequence((prev) => {
  //     return prev + 1;
  //   });
  //   mutateAsync({
  //     url: `/messages/${params?.id}`,
  //     body: {
  //       sequence: 1,
  //     },
  //     method: "patch",
  //   });
  // };

  // useEffect(() => {
  //   setSequence(params?.sequence);
  // }, [params?.sequence]);

  // useEffect(() => {
  //   if (isError) {
  //     setSequence(params?.sequence);
  //   }
  // }, [isError]);

  const messagePlacement = params?.platform_type_name;

  return (
    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
      {/* <LightTooltip title={"تصويت"} placement="right"> */}
      {/* <Box
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: "#B7A7FF",
                backgroundColor: "#F8F8FB",
                border: "1px solid #B7A7FF",
                borderRadius: "6px",
                padding: "4px",
              }}
            >
              <i className="bx bx-up-arrow-circle"></i>
            </Box> */}
      {/* <Box
          onClick={() => handleSequenceChange()}
          sx={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            color: "#F8F8FB",
            backgroundColor: "#B7A7FF",
            borderRadius: "6px",
            padding: "4px",
            gap: "3px",
          }}
        >
          <Box
            sx={{
              fontSize: "12px",
              marginBottom: "-2px",
              marginLeft: "3px",
            }}
          >
            {+sequence}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowCircleUpRoundedIcon sx={{ fontSize: "16px" }} />
          </Box>
        </Box>
      </LightTooltip> */}
      <GenderCell params={params} />
      <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
        <MessagePlacementCell messagePlacement={messagePlacement} />
        <ReplayDropMenu data={params} />
      </Box>
    </Box>
  );
};

export default SequenceCell;
