import {
  Box,
  IconButton,
  LinearProgress,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import { DataGridPro, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import { CustomNoRowsOverlay } from "../../../../components/shared/ui/custom-no-row-overly/custom-no-row-overly";
import { Socket, io } from "socket.io-client";
import React, { useEffect, useState } from "react";
import styles from "./live-messages.module.scss";
import EditableShowName from "../../../messages/components/editable-show-name/editable-show-name";
import PreviousMessages from "../../../messages/components/previous-messages/previous-messages";
import EditableClientLocation from "../../../messages/components/editable-client-location/editable-client-location";
import ActionsModal from "../../../messages/components/actions-model/actions-model";
import IsLiveReadCell from "../../../messages/components/table-cells/is-live-read-cell";
import MessageContentCell from "../../../messages/components/table-cells/message-content-cell";
import SequenceCell from "../../../messages/components/table-cells/sequence-cell";
import ActionsCell from "../../../messages/components/table-cells/actions-cell";
import { useMutateQuery } from "../../../../api/useMutateQuery";
import ClientNameCell from "../../../messages/components/table-cells/client-name-cell";
import { useGetQuery } from "../../../../api/useGetQuery";
import { handleIndexData } from "../../../../utils/handle-index-data";
import { handleColsWidth } from "../../../../utils/handle-cols-width";
import useFixedHeader from "../../../../hooks/useFixedHeader";

type MessageType = {
  city_id: number;
  city_name: string;
  client_gender: "male" | "female" | "other";
  client_id: number;
  content: string;
  created_at: string;
  deleted_at: string;
  full_name: string;
  gsm_message_id: number;
  is_favorite: boolean;
  is_live_read: boolean;
  is_seen: boolean;
  messageCount: string;
  messageCountWithLiveRead: string;
  id: number;
  message_type: "SMS" | "MMS" | "OtherMessageType";
  notes: string;
  phone_number: string;
  platform_id: number;
  platform_type_id: number;
  platform_type_name: string;
  port: number;
  radio_show_id: number;
  radio_show_name: string;
  region_id: number;
  region_name: string;
  sequence: number;
};

const LiveMessagesTable = React.memo(
  ({ globalSearch: initialGlobalSearch }: { globalSearch: string }) => {
    const isScreenLarge = useMediaQuery("(min-width: 1500px)");

    const limit = 50;
    const pageScrollPosition = 280;
    const [page, setPage] = useState(1);

    const [globalSearch, setGlobalSearch] =
      useState<string>(initialGlobalSearch);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [messageId, setMessageId] = useState<string>("");

    const [messages, setMessages] = useState([]);
    const [newMessages, setNewMessages] = useState([]);
    const [clientId, setClientId] = useState<string>("");
    const [openPrevMessageModal, setOpenPrevMessageModal] =
      useState<boolean>(false);

    const [timeoutId, setTimeoutId] = useState<number | null>(null);

    useEffect(() => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId: any = setTimeout(() => {
        setGlobalSearch(initialGlobalSearch);
      }, 1000);

      setTimeoutId(newTimeoutId);

      return () => {
        if (newTimeoutId) {
          clearTimeout(newTimeoutId);
        }
      };
    }, [initialGlobalSearch]);

    const { data, isLoading } = useGetQuery(
      `messages-${limit}-${page}-${globalSearch}`,
      "/messages",
      {
        limit: limit,
        offset: page * limit - limit,
        q: globalSearch,
      }
    );

    const [colsWidth, setColsWidth] = useState(() => {
      const savedColsWidth = JSON.parse(localStorage.getItem("colsWidth"));
      return (
        savedColsWidth || {
          is_live_read: 10,
          content: 500,
          full_name: 135,
          region: 60,
          radio_show_name: 70,
          sequence: 65,
          actions: 80,
        }
      );
    });

    const { data: genderCount } = useGetQuery(
      "locations",
      "/messages/gender-count"
    );

    const { mutateAsync } = useMutateQuery();

    const { isHeaderFixed } = useFixedHeader({ pageScrollPosition });

    useEffect(() => {
      setMessages(handleIndexData(data?.array));
    }, [data]);

    useEffect(() => {
      const socket: Socket<any> = io(
        `${import.meta.env.VITE_SOME_KEY_BASE_URL}`
      );
      let eventTimer: any;
      socket.on("message", (data: MessageType) => {
        setMessages((prev) => {
          if (prev?.length) {
            return [
              {
                client_id: data?.client_id,
                message_type: data?.message_type,
                id: data?.id,
                client_gender: data?.client_gender,
                radio_show_name: data?.radio_show_name,
                content: data?.content,
                platform_type_id: data?.platform_type_id,
                is_live_read: data?.is_live_read,
                deleted_at: data?.deleted_at,
                is_seen: data?.is_seen,
                created_at: data?.created_at,
                platform_id: data?.platform_id,
                is_favorite: data?.is_favorite,
                notes: data?.notes,
                radio_show_id: data?.radio_show_id,
                sequence: data?.sequence,
                region_id: data?.region_id,
                city_id: data?.city_id,
                region_name: data?.region_name,
                city_name: data?.city_name,
                platform_type_name: data?.platform_type_name,
                full_name: data?.full_name,
                messageCountWithLiveRead: data?.messageCountWithLiveRead,
                messageCount: data?.messageCount,
                port: data?.port,
                gsm_message_id: data?.gsm_message_id,
                phone_number: data?.phone_number,
                isNew: true,
                ...data,
              },
              ...prev,
            ];
          }

          setNewMessages((prevNewMessages) => [
            data?.id, // Store the ID of the new message
            ...prevNewMessages,
          ]);

          return [data, []];
        });
      });
      return () => {
        if (eventTimer) {
          clearTimeout(eventTimer);
        }
        socket.disconnect();
      };
    }, []);

    const columns: any[] = [
      {
        field: "is_live_read",
        headerName: "مقروءة",
        width: colsWidth?.is_live_read,
        renderCell: (params) => {
          return (
            <IsLiveReadCell params={params?.row} index={params.row.index} />
          );
        },
      },
      {
        field: "content",
        headerName: "الرسائل",
        width: colsWidth?.content,
        flex: colsWidth?.content === 500 ? 1 : null,
        renderCell: (params) => {
          return (
            <MessageContentCell
              params={params?.row}
              setOpenPrevMessageModal={setOpenPrevMessageModal}
              setClientId={setClientId}
            />
          );
        },
      },
      {
        field: "full_name",
        headerName: "الاسم",
        type: "text",
        width: colsWidth?.full_name,
        flex: colsWidth?.full_name === 135 ? 0.3 : 0,
        renderCell: (params) => {
          return <ClientNameCell params={params} />;
        },
        valueGetter: (params) => {
          return params?.row?.full_name;
        },
      },
      {
        field: "region",
        headerName: "المنطقة",
        editable: true,
        width: colsWidth?.region,
        flex: colsWidth.region === 60 ? 0.3 : 0,
        renderCell: (params) => {
          let neighborhood =
            data?.locations &&
            data?.locations[params?.row?.id]?.neighborhood_name;
          let subCity =
            data?.locations && data?.locations[params?.row?.id]?.sub_city_name;
          let city =
            data?.locations && data?.locations[params?.row?.id]?.city_name;

          if (subCity && city && neighborhood) {
            return (
              <Box
                sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}
              >{`${city} / ${subCity} / ${neighborhood}`}</Box>
            );
          } else if (subCity && city) {
            return (
              <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
                {`${subCity} / ${city}`}
              </Box>
            );
          } else if (neighborhood) {
            return (
              <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
                {neighborhood}
              </Box>
            );
          } else if (subCity) {
            return (
              <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
                {subCity}
              </Box>
            );
          } else if (city) {
            return (
              <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
                {city}
              </Box>
            );
          } else {
            return (
              <Box sx={{ fontSize: isScreenLarge ? "16px" : "14px" }}>
                لا يوجد
              </Box>
            );
          }
        },
        renderEditCell: (params: GridRenderEditCellParams) => (
          <EditableClientLocation
            params={params?.row}
            locationsHashMap={data?.locations}
          />
        ),
        valueGetter: (params) => {
          let region = params?.row?.region_name;
          let city = params?.row?.city_name;
          if (region && city) {
            return <Box>{`${region} / ${city}`}</Box>;
          } else if (region) {
            return <Box>{region}</Box>;
          } else if (city) {
            return <Box>{city}</Box>;
          }
          return "لا يوجد";
        },
      },
      {
        field: "radio_show_name",
        headerName: "البرنامج",
        editable: true,
        type: "text",
        width: colsWidth?.radio_show_name,
        flex: colsWidth.radio_show_name === 70 ? 0.3 : 0,
        renderCell: (params) => {
          return <Box>{params?.row?.radio_show_name || "لا يوجد"}</Box>;
        },
        renderEditCell: (params: GridRenderEditCellParams) => (
          <EditableShowName data={params?.row} />
        ),
      },
      {
        field: "sequence",
        headerName: "المعلومات",
        width: colsWidth?.sequence,
        renderCell: (params) => {
          return <SequenceCell params={params?.row} />;
        },
        valueGetter: (params) => {
          return +params?.row?.sequence;
        },
      },
      {
        field: "actions",
        headerName: "الاجراءات",
        width: colsWidth?.actions,
        disableExport: true,
        renderCell: (params) => {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "0px" }}>
              <ActionsCell params={params?.row} />
              <IconButton
                onClick={() => {
                  setOpenModal(true);
                  setMessageId(params?.row?.id);
                }}
              >
                <i
                  style={{ fontSize: "24px" }}
                  className="bx bx-dots-vertical-rounded"
                ></i>
              </IconButton>
            </Box>
          );
        },
      },
    ];

    const handleCellClick: any = (params) => {
      if (params.field === "content") {
        if (params?.row?.is_live_read) {
          mutateAsync({
            url: `/messages/${params?.row?.id}`,
            body: {
              isLiveRead: "false",
            },
            method: "patch",
          });
        } else {
          mutateAsync({
            url: `/messages/${params?.row?.id}`,
            body: {
              isLiveRead: "true",
            },
            method: "patch",
          });
        }
      }
    };

    return (
      <>
        <Box
          sx={{
            width: "100%",
            overflow: "scroll",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
            borderRadius: "6px",
          }}
        >
          <Box
            sx={{
              textAlign: "right",
              fontSize: "14px",
              marginRight: "15px",
              color: "#2E73FF",
            }}
          >
            (الاناث {genderCount?.femaleCount}) - (الذكور{" "}
            {genderCount?.maleCount}) - (غير محدد {genderCount?.noneCount}) -
            (عدد الرسائل{" "}
            {+genderCount?.femaleCount +
              +genderCount?.maleCount +
              +genderCount?.noneCount ?? 0}
            )
          </Box>
          <DataGridPro
            sx={{
              border: "none",
              ".muirtl-qvtrhg-MuiDataGrid-virtualScroller" :{
                paddingBottom: "200px",
              },
              ".muirtl-204u17-MuiDataGrid-main": {
                minHeight: "500px",
              },
              ".muirtl-axafay-MuiDataGrid-virtualScroller": {
                paddingBottom: "300px",
              },
              "& .MuiDataGrid-columnHeaders *": {
                fontWeight: 900,
              },
              "& .MuiDataGrid-columnHeaders": {
                position: isHeaderFixed ? "fixed" : "relative",
                top: "0",
                zIndex: 999,
                backgroundColor: "#fff",
              },
              ".MuiDataGrid-row:last-of-type": {
                paddingBottom: "200px !important",
              },
            }}
            columns={columns}
            rows={messages?.length > 0 ? messages : []}
            onColumnWidthChange={(e) => {
              handleColsWidth(e.colDef, setColsWidth, "colsWidth");
            }}
            getRowHeight={() => "auto"}
            getRowId={(row) => row?.id}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            getRowClassName={(params) => (
              params?.row?.is_live_read ? styles.is_seen_row_true : "",
              params?.row?.isNew ? styles.newMessage : ""
            )}
            loading={isLoading}
            onCellDoubleClick={handleCellClick}
          />
          <Pagination
            sx={{ margin: "10px 0" }}
            count={Math.ceil(data?.count / limit) || 0}
            onChange={(e, value) => setPage(value)}
            variant="outlined"
            shape="rounded"
          />
        </Box>
        <ActionsModal
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
          messageId={messageId}
        />
        {openPrevMessageModal && (
          <PreviousMessages
            openModal={openPrevMessageModal}
            handleCloseModal={() => {
              setOpenPrevMessageModal(false);
              setClientId("");
            }}
            clientId={clientId}
          />
        )}
      </>
    );
  }
);

export default LiveMessagesTable;
