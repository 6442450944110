import React, { useCallback, useEffect, useState } from "react";

const useFixedHeader = ({ pageScrollPosition }) => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    setIsHeaderFixed(scrollPosition > pageScrollPosition);
  }, [pageScrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return { isHeaderFixed };
};

export default useFixedHeader;
