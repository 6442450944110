import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styles from "./unread-program-item.module.scss";
import { useGetQuery } from "../../../../api/useGetQuery";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../../../utils/format-number";

const UnreadProgramItem = ({ startDate, endDate }) => {
  const isLargeScreen = useMediaQuery("(min-width: 1280px)");
  const isMediumScreen = useMediaQuery("(min-width: 960px)");
  const isSmallScreen = useMediaQuery("(min-width: 600px)");

  const { data, isLoading } = useGetQuery(
    `messages-unread-messages-count-${startDate}-${endDate}`,
    "/messages/unread-messages-count",
    {
      startDate: startDate,
      endDate: endDate,
    }
  );

  const navigate = useNavigate();

  const isBorderAdded = (index: number) => {
    return isLargeScreen
      ? index > 3
        ? true
        : false
      : isMediumScreen
      ? index > 2
        ? true
        : false
      : isSmallScreen
      ? index > 1
        ? true
        : false
      : true;
  };

  const getColorForNumber = (number: number) => {
    if (number < 30) {
      return { backgroundColor: "#D3E2FF" };
    } else if (number < 100) {
      return { backgroundColor: "#FFF0D3" };
    } else {
      return { backgroundColor: "#FFD3D3" };
    }
  };

  return (
    <div className={styles.unread_program_item}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container className={styles.grid_container}>
          <>
            {data?.map((item, index) => (
              <Grid
                key={item?.showId}
                className={styles.grid_item}
                item
                lg={3}
                md={4}
                sm={6}
                xs={12}
              >
                <Box
                  className={`${styles.grid_content} ${
                    isBorderAdded(index) ? styles.border_top_item : ""
                  }`}
                  onClick={() => {
                    if (item?.showId) {
                      navigate(`/messages/program-messages/${item?.showId}`);
                    } else if (item?.showId === null) {
                      navigate(`/messages/program-messages/0`);
                    }
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography className={styles.grid_content__title}>
                    {item?.showName || "برامج اخرى"}
                  </Typography>
                  <Typography
                    className={styles.grid_content__number}
                    sx={getColorForNumber(+item?.count)}
                  >
                    {formatNumber(item?.count)}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </>
        </Grid>
      )}
    </div>
  );
};

export default UnreadProgramItem;
