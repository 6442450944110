import CameraIcon from "@mui/icons-material/Camera";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";

const sidebarNav = [
  {
    link: "/",
    section: "messages",
    icon: (
      <i
        style={{ marginRight: "-10px", marginTop: "5px" }}
        className="bx bx-chat"
      ></i>
    ),
    text: "ادارة الرسائل",
  },
  {
    link: "/live-messages",
    section: "live-messages",
    icon: (
      <i
        style={{ marginRight: "-10px", marginTop: "5px" }}
        className="bx bx-podcast"
      ></i>
    ),
    text: "الرسائل المباشرة",
  },
  {
    link: "/reporter",
    section: "reporter",
    icon: (
      <RecordVoiceOverOutlinedIcon
        sx={{ marginRight: "-5px", marginTop: "5px", marginLeft: "-12px" }}
      />
    ),
    text: "انا مراسل",
  },
  {
    link: "/express-yourself",
    section: "express-yourself",
    icon: (
      <KeyboardVoiceOutlinedIcon
        sx={{ marginRight: "-5px", marginTop: "5px", marginLeft: "-12px" }}
      />
    ),
    text: "عبر عن رايك",
  },
  {
    link: "/entreaties",
    section: "entreaties",
    icon: (
      <i
        style={{ marginRight: "-10px", marginTop: "5px" }}
        className="bx bx-message-square-edit"
      ></i>
    ),
    text: "المناشدات",
  },
  {
    link: "/grouped-messages",
    section: "grouped-messages",
    icon: (
      <i
        style={{ marginRight: "-10px", marginTop: "5px" }}
        className="bx bx-message-square-edit"
      ></i>
    ),
    text: "مجموعات الرسائل",
  },
];

export default sidebarNav;
