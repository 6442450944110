import { useEffect, useState } from "react";
import { Badge, Box, Button, Fade, Menu, Typography } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import BasicRangeShortcuts from "../../components/shared/inputs/date-range-picker-mui/CustomShortcutItem";
import DateRangePickerMui from "../../components/shared/inputs/date-range-picker-mui/DateRangePickerMui";
import SearchModal from "./components/search-modal/search-modal";
import TableMessages from "./components/table-messages/table-messages";
import UnreadMessages from "./components/unread-messages/unread-messages";
import styles from "./messages.module.scss";
import BrowserTabTitle from "../../components/shared/browser-tab-title/browser-tab-title";
import BulkEditProgram from "./components/bulk-edit-program/bulk-edit-program";
import { CustomTextField } from "../../components/shared/inputs/input/BootStrapInput";
import FilterListIcon from "@mui/icons-material/FilterList";
import WhatsUpQrModal from "./components/qr-code-modal/whats-up-qr-modal";
import { Socket, io } from "socket.io-client";
import { useSnackbar } from "notistack";
import { LightTooltip } from "../../components/shared/ui/custom-tooltip/custom-tooltip";

const Messages = () => {
  const [hasNameFilter, setHasNameFilter] = useState<string>("");
  const [hiddenFilter, setHiddenFilter] = useState<number | undefined>();
  const [isSeenFilter, setIsSeenFilter] = useState<string | undefined>("all");
  const [globalMessagesSearch, setGlobalMessagesSearch] = useState("");
  const [selectedIds, setSelectedIds] = useState<any>([]);

  const [openModalQrCode, setOpenModalQrCode] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const today = dayjs();
  const currentMonthRange: DateRange<Dayjs> = [
    today.add(3, "hours"),
    today.add(3, "hours"),
  ];

  const [startDate, setStartDate] = useState<string>(
    currentMonthRange[0].toISOString()
  );
  const [endDate, setEndDate] = useState<string>(
    currentMonthRange[1].toISOString()
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateRange = (e: DateRange<Dayjs>) => {
    setStartDate(e[0]?.toISOString());
    setEndDate(e[1]?.toISOString());
  };

  const [qrData, setQrData] = useState("");

  useEffect(() => {
    const socket: Socket<any> = io("https://qri.alghad.fm");

    let eventTimer: NodeJS.Timeout | null;

    socket.on("qr_code", (data: any) => {
      //   setCalls(data);
      //   setLastMessageTime(Date.now());
      setQrData(data?.qr);
      if (eventTimer) {
        clearTimeout(eventTimer);
      }

      eventTimer = setTimeout(() => {
        // setCalls([]);
      }, 2000);
    });
    return () => {
      if (eventTimer) {
        clearTimeout(eventTimer);
      }
      socket.disconnect();
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (qrData?.length > 0) {
      enqueueSnackbar("Qr code متوفر الان", { variant: "success" });
    }
  }, [qrData]);

  return (
    <BrowserTabTitle title="ادارة الرسائل">
      <Box>
        <Box component="section" className={styles.messages_header_container}>
          <div className={styles.messages_header}>ادارة الرسائل</div>
          <DateRangePickerMui
            startDate={startDate}
            endDate={endDate}
            handleClick={handleClick}
          />
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <BasicRangeShortcuts
              handleDateRange={handleDateRange}
              startDate={startDate}
              endDate={endDate}
            />
          </Menu>
        </Box>
        <Box>
          <UnreadMessages startDate={startDate} endDate={endDate} />
        </Box>
        <Box component="section" className={styles.filters_container}>
          <Box>
            <BulkEditProgram selectedIds={selectedIds} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <CustomTextField
              sx={{ width: "200px", marginTop: "3px", padding: "7px 10px" }}
              placeholder="البحث.."
              onChange={(e) => setGlobalMessagesSearch(e.target.value)}
            />
            <Button
              className={styles.search_button}
              onClick={() => setOpenModal(true)}
            >
              <FilterListIcon sx={{ fontSize: "20px", color: "black" }} />
              <Typography sx={{ fontSize: "12px", color: "black" }}>
                الفلاتر
              </Typography>
            </Button>
            <Badge
              badgeContent={qrData?.length > 0 ? "" : null}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Button
                className={styles.search_button}
                onClick={() => setOpenModalQrCode(true)}
                disabled={qrData?.length > 0 ? false : true}
              >
                <Typography sx={{ fontSize: "12px", color: "black" }}>
                  كود QR
                </Typography>
              </Button>
            </Badge>
          </Box>
        </Box>
        <Box
          component="section"
          sx={{
            marginTop: "30px",
          }}
        >
          <TableMessages
            hasNameFilter={hasNameFilter}
            hiddenFilter={hiddenFilter}
            startDate={startDate}
            endDate={endDate}
            isSeenFilter={isSeenFilter}
            setSelectedIds={setSelectedIds}
            globalMessagesSearch={globalMessagesSearch}
          />
        </Box>
        <SearchModal
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
          setHasNameFilter={setHasNameFilter}
          setHiddenFilter={setHiddenFilter}
          setIsSeenFilter={setIsSeenFilter}
          isSeenFilter={isSeenFilter}
        />
        <WhatsUpQrModal
          openModal={openModalQrCode}
          handleCloseModal={() => setOpenModalQrCode(false)}
          qrData={qrData}
        />
      </Box>
    </BrowserTabTitle>
  );
};

export default Messages;
