import axios from "axios";

const api = axios.create({
  baseURL: import.meta.env.VITE_SOME_KEY_BASE_URL,
});

const handleUnauthorizedError = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.href = "/login";
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      error.response.status === 403
    ) {
      handleUnauthorizedError();
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const accessToken: string | null = localStorage.getItem("token");

    if (accessToken && config.headers) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export { api };
