import { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import UnreadProgramItem from "../unread-program-item/unread-program-item";

type UnreadMessagesProps = {
  startDate: any;
  endDate: any;
};
const UnreadMessages = (props: UnreadMessagesProps) => {
  const { startDate, endDate } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ marginTop: "20px" }}>
      <List
        sx={{
          boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
          padding: 0,
          overflow: "hidden",
          borderRadius: "6px",
        }}
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          sx={{
            height: "56px",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={handleClick}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i
              style={{ fontSize: "24px", color: "#2E73FF" }}
              className="bx bx-chat"
            ></i>
            <Typography sx={{ fontWeight: 500 }}>
              الرسائل الغير مقروءة
            </Typography>
          </Box>
          {open ? (
            <IconButton>
              <i className="bx bx-chevron-down"></i>
            </IconButton>
          ) : (
            <IconButton>
              <i className="bx bx-chevron-right"></i>
            </IconButton>
          )}
        </ListItemButton>
        <Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid item xs={12} sm={4} md={4}>
              <UnreadProgramItem startDate={startDate} endDate={endDate} />
            </Grid>
          </Collapse>
        </Box>
      </List>
    </Box>
  );
};

export default UnreadMessages;
