import React, { useState } from "react";
import BrowserTabTitle from "../../../components/shared/browser-tab-title/browser-tab-title";
import { Box, Button, Fade, Menu, Typography } from "@mui/material";
import styles from "../messages.module.scss";
import DateRangePickerMui from "../../../components/shared/inputs/date-range-picker-mui/DateRangePickerMui";
import BasicRangeShortcuts from "../../../components/shared/inputs/date-range-picker-mui/CustomShortcutItem";
import BulkEditProgram from "../components/bulk-edit-program/bulk-edit-program";
import { CustomTextField } from "../../../components/shared/inputs/input/BootStrapInput";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import FilterListIcon from "@mui/icons-material/FilterList";
import ProgramMessagesTable from "./components/program-messages-table";
import SearchModal from "../components/search-modal/search-modal";
import { useParams } from "react-router-dom";

const ProgramMessages = () => {
  const { radioShowId } = useParams();

  const [hasNameFilter, setHasNameFilter] = useState<string>("");
  const [hiddenFilter, setHiddenFilter] = useState<number | undefined>();
  const [isSeenFilter, setIsSeenFilter] = useState<string | undefined>("false");
  const [globalMessagesSearch, setGlobalMessagesSearch] = useState("");
  const [selectedIds, setSelectedIds] = useState<any>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const today = dayjs();
  const currentMonthRange: DateRange<Dayjs> = [
    today.add(3, "hours"),
    today.add(3, "hours"),
  ];

  const [startDate, setStartDate] = useState<string>(
    currentMonthRange[0].toISOString()
  );
  const [endDate, setEndDate] = useState<string>(
    currentMonthRange[1].toISOString()
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateRange = (e: DateRange<Dayjs>) => {
    setStartDate(e[0]?.toISOString());
    setEndDate(e[1]?.toISOString());
  };

  return (
    <BrowserTabTitle title="رسائل البرامج">
      <Box>
        <Box component="section" className={styles.messages_header_container}>
          <div className={styles.messages_header}>رسائل البرامج</div>
          <DateRangePickerMui
            startDate={startDate}
            endDate={endDate}
            handleClick={handleClick}
          />
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <BasicRangeShortcuts
              handleDateRange={handleDateRange}
              startDate={startDate}
              endDate={endDate}
            />
          </Menu>
        </Box>
        <Box component="section" className={styles.filters_container}>
          <Box>
            <BulkEditProgram selectedIds={selectedIds} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <CustomTextField
              sx={{ width: "200px", marginTop: "3px", padding: "7px 10px" }}
              placeholder="البحث.."
              onChange={(e) => setGlobalMessagesSearch(e.target.value)}
            />
            <Button
              className={styles.search_button}
              onClick={() => setOpenModal(true)}
            >
              <FilterListIcon sx={{ fontSize: "20px", color: "black" }} />
              <Typography sx={{ fontSize: "12px", color: "black" }}>
                الفلاتر
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box
          component="section"
          sx={{
            marginTop: "30px",
          }}
        >
          <ProgramMessagesTable
            hasNameFilter={hasNameFilter}
            hiddenFilter={hiddenFilter}
            startDate={startDate}
            endDate={endDate}
            isSeenFilter={isSeenFilter}
            setSelectedIds={setSelectedIds}
            globalMessagesSearch={globalMessagesSearch}
            radioShowId={radioShowId}
          />
        </Box>
        <SearchModal
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
          setHasNameFilter={setHasNameFilter}
          setHiddenFilter={setHiddenFilter}
          setIsSeenFilter={setIsSeenFilter}
          isSeenFilter={isSeenFilter}
        />
      </Box>
    </BrowserTabTitle>
  );
};

export default ProgramMessages;
