import { useState } from "react";
import { Box, Button, FormLabel, MenuItem, Typography } from "@mui/material";
import CustomModal from "../../../../components/shared/ui/custom-modal/custom-modal";
import { CustomSelect } from "../../../../components/shared/inputs/custom-select/custom-select";
import { CustomTextField } from "../../../../components/shared/inputs/input/BootStrapInput";

type SearchModalTypes = {
  openModal: boolean;
  handleCloseModal: () => void;
  setHasNameFilter: (name: string) => void;
  setHiddenFilter: (name: number) => void;
  setIsSeenFilter: (name: string) => void;
  isSeenFilter: string;
};

const SearchModal: React.FC<SearchModalTypes> = (props) => {
  const {
    openModal,
    handleCloseModal,
    setHasNameFilter,
    setHiddenFilter,
    setIsSeenFilter,
    isSeenFilter,
  } = props;

  const [filterData, setFilterData] = useState({
    hasName: "hasName",
    isSeen: isSeenFilter,
    hidden: 0,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "hasName" && value === "noName") {
      setFilterData((prev) => ({
        ...prev,
        clientName: "لايوجد",
      }));
    } else if (name === "hasName" && value === "hasName") {
      setFilterData((prev) => ({
        ...prev,
        clientName: "",
      }));
    }
  };

  const handleSearchClick = () => {
    setHasNameFilter(filterData.hasName);
    setHiddenFilter(filterData.hidden);
    setIsSeenFilter(filterData.isSeen);

    handleCloseModal();
  };

  return (
    <CustomModal openModal={openModal} handleCloseModal={handleCloseModal}>
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        الفلترة
      </Typography>
      <br />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box>
          <FormLabel
            sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "20px" }}
          >
            نوع المرسلين
          </FormLabel>
          <CustomSelect
            placeholder="Enter Role Name"
            id="bootstrap-input"
            fullWidth
            value={filterData.hasName}
            onChange={(e) => handleInputChange(e)}
            name="hasName"
          >
            <MenuItem value={"hasName"}>الكل</MenuItem>
            <MenuItem value={"noname"}>بدون اسماء</MenuItem>
          </CustomSelect>
        </Box>
        <Box>
          <FormLabel
            sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "20px" }}
          >
            المقروءة
          </FormLabel>
          <CustomSelect
            id="bootstrap-input"
            fullWidth
            value={filterData.isSeen}
            onChange={(e) => handleInputChange(e)}
            name="isSeen"
          >
            <MenuItem value={"all"}>الكل</MenuItem>
            <MenuItem value={"true"}>المقروءة</MenuItem>
            <MenuItem value={"false"}>غير المقروءة</MenuItem>
          </CustomSelect>
        </Box>
        <Box>
          <FormLabel
            sx={{ fontSize: "14px", fontWeight: 600, marginBottom: "20px" }}
          >
            نوع الرسائل
          </FormLabel>
          <CustomSelect
            placeholder="Enter Role Name"
            id="bootstrap-input"
            fullWidth
            defaultValue={0}
            name="hidden"
            value={filterData.hidden}
            onChange={(e) => handleInputChange(e)}
          >
            <MenuItem value={0}>غير المخفي</MenuItem>
            <MenuItem value={1}>مخفي</MenuItem>
            <MenuItem value={2}>غير المخفي + مخفي</MenuItem>
          </CustomSelect>
        </Box>
        <Button
          sx={{
            backgroundColor: "#2E73FF",
            color: "white",
            padding: "7px 20px",
            "&:hover": {
              backgroundColor: "#2E73FFbb",
              color: "white",
            },
            textAlign: "left",
          }}
          onClick={handleSearchClick}
        >
          البحث
        </Button>
      </Box>
    </CustomModal>
  );
};

export default SearchModal;
