import { useEffect, useRef, useState } from "react";
import { useMutateQuery } from "../../../../api/useMutateQuery";

const EditableClientName = (props) => {
  const { mutateAsync, isError, isSuccess } = useMutateQuery();

  const componentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      mutateAsync({
        url: `/clients/${props?.row?.client_id}`,
        body: {
          fullName: props?.clientName,
        },
        method: "patch",
      });
    }
  };
  
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      mutateAsync({
        url: `/clients/${props?.row?.client_id}`,
        body: {
          fullName: props?.clientName,
        },
        method: "patch",
      });
    } else if (event.key === " ") {
      componentRef?.current?.focus();
      handleInputChange(event, " ");
    }

    if ((event.ctrlKey || event.metaKey) && event.key === "a") {
      componentRef?.current?.select();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [props?.clientName, props?.row?.client_id, mutateAsync]);

  const handleInputChange = (e, val?) => {
    const { value } = e.target;

    if (val) {
      props.setClientName((prev) => prev + val);
    } else {
      props.setClientName(value);
    }
  };

  useEffect(() => {
    componentRef?.current?.focus();
  }, [props?.row?.full_name]);

  useEffect(() => {
    if (isError) {
      props.setClientName(props?.row?.full_name);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      props?.setIsEditable(false);
    }
  }, [isSuccess]);

  return (
    <input
      ref={componentRef}
      type="text"
      value={props.clientName}
      onChange={(e) => handleInputChange(e)}
      onKeyDown={handleKeyDown}
      style={{
        width: "100%",
        height: "100%",
        border: "none",
        textAlign: "center",
        outline: "none",
      }}
    />
  );
};

export default EditableClientName;
