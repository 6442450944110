import { Box, Divider, Grid, Typography } from "@mui/material";
import CustomModal from "../../../../components/shared/ui/custom-modal/custom-modal";

function hasIamReporterExtension(link) {
  // Use a regular expression to check if the link ends with ".i-am-reporter"
  var regex = /\.i-am-reporter$/i; // The 'i' flag makes the match case-insensitive
  return regex.test(link);
}

function checkFileType(path) {
  // Extract the first segment of the path
  var segments = path.split("/");
  var firstSegment = segments[0];

  // Check if the first segment corresponds to "audio," "image," or "video"
  if (firstSegment === "audio") {
    return "audio";
  } else if (firstSegment === "image") {
    return "image";
  } else if (firstSegment === "video") {
    return "video";
  } else {
    return "unknown"; // If none of the expected types are matched
  }
}

const ReporterMedia = ({ openModal, handleCloseModal, reporterMedia }) => {
  return (
    <CustomModal
      openModal={openModal}
      handleCloseModal={handleCloseModal}
      customStyle={{ width: "80%", height: "80%", overflow: "auto" }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        صور المراسلين
      </Typography>
      <Divider />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {reporterMedia?.map((media) => {
          if (hasIamReporterExtension(media.path)) {
            return null;
          } else if (checkFileType(media?.path) === "image") {
            return (
              <Grid item xs={6} sm={4} key={media.id}>
                <Box
                  sx={{
                    height: "350px",
                    overflow: "hidden",
                    borderRadius: "6px",
                  }}
                >
                  <img
                    style={{ width: "100%", objectFit: "contain" }}
                    src={`${
                      import.meta.env.VITE_SOME_KEY_BASE_URL
                    }/gallery?path=${media.path}`}
                    alt="media"
                    onClick={() => {
                      window.open(
                        `${
                          import.meta.env.VITE_SOME_KEY_BASE_URL
                        }/gallery?path=${media.path}`,
                        "_blank"
                      );
                    }}
                  />
                </Box>
              </Grid>
            );
          } else if (checkFileType(media?.path) === "audio") {
            return (
              <Grid item xs={6} sm={4} key={media.id}>
                <Box
                  sx={{
                    height: "350px",
                    overflow: "hidden",
                    borderRadius: "6px",
                  }}
                >
                  <audio
                    controls
                    src={`${
                      import.meta.env.VITE_SOME_KEY_BASE_URL
                    }/gallery?path=${media.path}`}
                  />
                </Box>
              </Grid>
            );
          } else if (checkFileType(media?.path) === "video") {
            return (
              <Grid item xs={6} sm={4} key={media.id}>
                <Box
                  sx={{
                    height: "350px",
                    overflow: "hidden",
                    borderRadius: "6px",
                  }}
                >
                  <video
                    controls
                    src={`${
                      import.meta.env.VITE_SOME_KEY_BASE_URL
                    }/gallery?path=${media.path}`}
                    onClick={() => {
                      window.open(
                        `${
                          import.meta.env.VITE_SOME_KEY_BASE_URL
                        }/gallery?path=${media.path}`,
                        "_blank"
                      );
                    }}
                  />
                </Box>
              </Grid>
            );
          }
        })}
      </Grid>
    </CustomModal>
  );
};

export default ReporterMedia;
