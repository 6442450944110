import { useMediaQuery } from "@mui/material";
import { DateRange, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "الاسبوع الحالي",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today];
    },
  },
  {
    label: "الاسبوع السابق",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "اخر ٧ ايام",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "الشهر الحالي",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today];
    },
  },
  {
    label: "اخر ٣٠ يوم",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, "day"), today];
    },
  },
  {
    label: "السنة الحالية",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today];
    },
  },
  {
    label: "السنة السابقة",
    getValue: () => {
      const today = dayjs();
      const lastYear = today.subtract(1, "year");
      return [lastYear.startOf("year"), lastYear.endOf("year")];
    },
  },
  { label: "اعادة", getValue: () => [null, null] },
];

type DateRangeTypes = {
  handleDateRange: (e: DateRange<Dayjs>) => void;
  startDate: any;
  endDate: any;
};

export default function BasicRangeShortcuts({
  handleDateRange,
  startDate,
  endDate,
}: DateRangeTypes) {
  const currentMonthRange: DateRange<Dayjs> = [
    dayjs(startDate),
    dayjs(endDate),
  ];

  const isSmallerScreen = useMediaQuery("(max-width: 700px)");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["SingleInputDateRangeField"]}>
        {!isSmallerScreen ? (
          <StaticDateRangePicker
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
            calendars={2}
            onChange={(e: DateRange<dayjs.Dayjs>) => {
              let newE: any = [];
              newE[0] = e[0]?.add(3, "hours");
              newE[1] = e[1]?.add(3, "hours");

              handleDateRange(newE);
            }}
            defaultValue={currentMonthRange}
            sx={{
              direction: "ltr !important",
            }}
          />
        ) : (
          <StaticDateRangePicker
            defaultValue={currentMonthRange}
            sx={{
              [`.${pickersLayoutClasses.contentWrapper}`]: {
                alignItems: "center",
              },
            }}
            onChange={(e: DateRange<dayjs.Dayjs>) => {
              let newE: any = [];
              newE[0] = e[0]?.add(3, "hours");
              newE[1] = e[1]?.add(3, "hours");

              handleDateRange(newE);
            }}
          />
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
}
