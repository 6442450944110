import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import React from "react";
import ReactDOM from "react-dom/client";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import App from "./App";
import AuthProvider from "./context/GlobalStateContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";

const myTheme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#2E73FF",
    },
    secondary: {
      main: "#DAD8FD",
    },
  },
  typography: {
    fontFamily: `"myFirstFont", sans-serif`,
  },
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(import.meta.env.VITE_SOME_KEY_MUI_LICENSE);

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://c1b8d2ec1826a976758d25ce8f63ffbf@o4506500666032128.ingest.sentry.io/4506501451939840",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <CacheProvider value={cacheRtl}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={myTheme}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </CacheProvider>
  </React.StrictMode>
);
