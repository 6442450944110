import { Box } from "@mui/material";
import { LightTooltip } from "../../../../components/shared/ui/custom-tooltip/custom-tooltip";
import SmartphoneRoundedIcon from "@mui/icons-material/SmartphoneRounded";

const MessagePlacementCell = ({ messagePlacement }) => {
  return (
    <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
      {messagePlacement === "sms" ? (
        <LightTooltip title={"رسائل SMS"} placement="right">
          <Box
            sx={{
              textAlign: "center",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "white",
              background: "linear-gradient(180deg, #62F57B 100%, #0BB727 100%)",
              borderRadius: "6px",
              padding: "5px 4px",
            }}
          >
            <i className="bx bxs-message-rounded"></i>
          </Box>
        </LightTooltip>
      ) : messagePlacement === "mobile" ? (
        <LightTooltip title={"رسائل التطبيق"} placement="right">
          <Box
            sx={{
              textAlign: "center",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "white",
              background: "linear-gradient(180deg, #1AD6FF 100%, #0B98B7 100%)",
              borderRadius: "6px",
              padding: "5px 4px",
            }}
          >
            <SmartphoneRoundedIcon sx={{ fontSize: "16px" }} />
          </Box>
        </LightTooltip>
      ) : messagePlacement === "telegram" ? (
        <LightTooltip title={"رسائل التلكرام"} placement="right">
          <Box
            sx={{
              textAlign: "center",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "white",
              background: "linear-gradient(180deg, #8CCAFF 100%, #229ED9 100%)",
              borderRadius: "6px",
              padding: "4px",
            }}
          >
            <i className="bx bxl-telegram"></i>
          </Box>
        </LightTooltip>
      ) : messagePlacement === "whatsapp" ? (
        <LightTooltip title={"رسائل الواتساب"} placement="right">
          <Box
            sx={{
              textAlign: "center",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: "white",
              background: "linear-gradient(180deg, #62F57B 100%, #0BB727 100%)",
              borderRadius: "6px",
              padding: "4px",
            }}
          >
            <i className="bx bxl-whatsapp"></i>
          </Box>
        </LightTooltip>
      ) : null}
    </Box>
  );
};

export default MessagePlacementCell;
